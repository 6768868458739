(function (module) {
    'use strict';

    module.factory('appraisalReportSvc', appraisalReportSvc);

    function appraisalReportSvc($http, amsConst, FileSaver, alertSvc, environmentSvc) {

        const hostUrl = environmentSvc.isDev() ? environmentSvc.getDevApiUrl() : location.origin;
        return {
            getReports,
            exportSimpleReport,
            exportDetailedReport,
            getSatisfactionGradeIcon,
            getRecommendAsTCIcon
        };
        /**
         * 
         * @param {Object} searchParams Single object with fields societyId, commissionId, years, pevTc
         */
        function getReports(searchParams) {
            const url = new URL(`${hostUrl}/webapi/odata/GetAppraisalReportSummaryList`);

            url.searchParams.set('$orderby', 'appraiseeName asc');

            const filterParams = createFilterParams(searchParams);
            if (filterParams) url.searchParams.set('$filter', filterParams);

            return $http.get(url.toString())
                .then(response => response.data.value);
        }

        async function exportSimpleReport(searchParams,includeConfidential) {
            alertSvc.confirm(
                'This export may contain sensitive or personally identifying information about volunteers. Please keep that in mind when storing or distributing the document and delete the file after working with it.',
                doExport
            );

            function doExport() {
                const url = new URL(`${hostUrl}/webapi/odata/GetAppraisalReportSummaryList`);
                url.searchParams.set('$orderby', 'appraiseeName asc');

                const filterParams = createFilterParams(searchParams);
                if (filterParams) url.searchParams.set('$filter', filterParams);

                $http.get(url.toString())
                    .then(response => {
                        const data = response.data.value.map(d => ({
                            'Appraisee Name': d.appraiseeName,
                            'Review Year': d.reviewYear,
                            'Society Code': d.societyCode,
                            'Commission Name': d.commissionName,
                            'PEV/TC': d.pevTc,
                            'Appraiser Name': d.appraiserName,
                            'Organization Name': d.organizationName,
                            'Review Type Code': d.reviewTypeCode,
                            'Previous PEV Experience': d.previousPevExperience,
                            'Appraisal Type': d.appraisalTypeName,
                            'Satisfaction Grade': d.satisfactionGrade,
                            'Comments': d.comment,
                            'Confidential': d.confidential,
                            'Recommend TC': d.recommendAsTC,
                            'Submitted': moment(d.submittedDate).format('M/D/YYYY')
                        }));
                        if (!includeConfidential) {
                            data.forEach(function (x) { delete x.Confidential; })
                        }
                        saveCsv(data, 'simple_report.csv');
                    })
                    .catch(() => console.error('Problem retrieving data for Simple Report'));
            }
        }

        async function exportDetailedReport(searchParams, includeConfidential, isAllAppraisals) {
            alertSvc.confirm(
                'This export may contain sensitive or personally identifying information about volunteers. Please keep that in mind when storing or distributing the document and delete the file after working with it.',
                doExport
            );

            function doExport() {
                const apiFuncStr = isAllAppraisals ? 'GetAllAppraisalReportDetailList' : 'GetAppraisalReportDetailList';
                const url = new URL(`${hostUrl}/webapi/odata/${apiFuncStr}`);
                url.searchParams.set('$orderby', 'appraiseeName asc');

                const filterParams = createFilterParams(searchParams);
                if (filterParams) url.searchParams.set('$filter', filterParams);

                $http.get(url.toString())
                    .then(response => {

                        let data = response.data.value;

                        //when detailed report exports, the list includes all other evaluations even though a certain comment, satisfaction, confidential, recommendAsTC option is selected
                        if (searchParams.includeOthers === true) {
                            let includeArr = data.filter(ar => (searchParams.satisfaction == "" || ar.satisfactionGrade == searchParams.satisfaction) &&
                                (searchParams.years.find(y => y === ar.reviewYear)) &&
                                (searchParams.recommendAsTC == "" || (ar.recommendAsTC == searchParams.recommendAsTC)) &&
                                (searchParams.confidential == "" ||  ( ar.confidential && searchParams.confidential == "With Confidential Comments") || (searchParams.confidential == "Without Confidential Comments")) &&
                                (searchParams.comments === "" || ( ar.comment && searchParams.comments == "With Comments") || (searchParams.comments == "Without Comments"))
                            );

                            if (includeArr && includeArr.length > 0) {
                                data = data.filter(ar => includeArr.find(pid => ar.appraiseePersonId === pid.appraiseePersonId));
                            }
                        }

                        let mappedData = data.map(d => ({
                            'Appraisee Name': d.appraiseeName,
                            'Review Year': d.reviewYear,
                            'Society Code': d.societyCode,
                            'Commission Name': d.commissionName,
                            'PEV/TC': d.pevTc,
                            'Appraiser Name': d.appraiserName,
                            'Organization Name': d.organizationName,
                            'Review Type Code': d.reviewTypeCode,
                            'Previous PEV Experience': d.previousPevExperience,
                            'Appraisal Type': d.appraisalTypeName,
                            'Satisfaction Grade': d.satisfactionGrade,
                            'Comments': d.comment,
                            'Confidential': d.confidential,
                            'Recommend TC': d.recommendAsTC,
                            'Competency Group': d.competencyGroup,
                            'Competency Question': d.competencyQuestion,
                            'Competency Answer': d.competencyAnswer,
                            'Submitted': moment(d.submittedDate).format('M/D/YYYY')
                        }));



                        if (!includeConfidential) {
                            mappedData.forEach(function (x) { delete x.Confidential; })
                        }
                        saveCsv(mappedData, 'detailed_report.csv');
                    })
                    .catch(() => console.error('Problem retrieving data for Detailed Report'));
            }

        }

        function getSatisfactionGradeIcon (satisfactionGrade) {
            if (satisfactionGrade == "Yes, without reservation") {
                return "fa-check success-color";
            } else if (satisfactionGrade == "Yes, but with some reservation") {
                return "fa-check warning-color";
            } else if (satisfactionGrade == "No") {
                return "fa-times danger-color";
            };
        }

        function getRecommendAsTCIcon(rc) {
            if (rc == "Yes") {
                return "fa-check success-color";
            } else if (rc == "Yes, but with additional experience") {
                return "fa-check warning-color";
            } else if (rc == "No") {
                return "fa-times danger-color";
            };
        }

        /**
         * Parses search params for use in OData urls.
         * @param {object} searchParams
         */
        function createFilterParams(searchParams) {
            if (searchParams && Object.keys(searchParams.length > 0)) {
                let {
                    societyId,
                    commissionIds,
                    years,
                    pevTc,
                    isPreviousRecentReview,
                    appraiseePersonId,
                    personSearch,
                    appraisalTypes,
                    satisfactions,
                    recommendAsTCs,
                    confidential,
                    comments,
                    submittedFrom,
                    submittedTo,
                    userPersonId
                } = searchParams;
                const filters = [];

                if (isPreviousRecentReview) {
                    years = null;
                }

                if (societyId) {
                    filters.push(`societyId eq ${societyId}`);
                }

                if (commissionIds && commissionIds.length > 0) {
                    const comFilters = commissionIds.map(t => `commissionId eq ${t}`);
                    filters.push(`(${comFilters.join(' or ')})`);
                }

                if (years && years.length > 0) {
                    const yearFilters = years.map(y => `reviewYear eq ${y}`);
                    filters.push(`(${yearFilters.join(' or ')})`);
                }

                if (pevTc) {
                    filters.push(`(indexof(pevTc, '${pevTc}') ge 0)`);
                }

                if (isPreviousRecentReview) {
                    filters.push('isPreviousRecentReview eq true');
                }

                if (appraiseePersonId) {
                    filters.push(`appraiseePersonId eq ${appraiseePersonId}`)
                }

                if (personSearch) {
                    const names = personSearch.trim().split(/\s+/);
                    names.forEach(n => {
                        filters.push(`(indexof(appraiseeName,'${n}') ge 0)`);
                    });
                }

                if (appraisalTypes && appraisalTypes.length > 0) {
                    const fltr = appraisalTypes.map(t => `appraisalTypeId eq ${t}`);
                    filters.push(`(${fltr.join(' or ')})`);
                }

                if (satisfactions && satisfactions.length > 0) {
                    const fltr = satisfactions.map(t => `satisfactionGrade eq '${t.id}'`);
                    filters.push(`(${fltr.join(' or ')})`);
                }

                if (recommendAsTCs && recommendAsTCs.length > 0 && searchParams.includeOthers === false) {
                    const fltr = recommendAsTCs.map(t => `recommendAsTC eq '${t.id}'`);
                    filters.push(`(${fltr.join(' or ')})`);
                }

                if (comments && searchParams.includeOthers === false) {
                    if (comments === 'With Comments')
                        filters.push(`length(comment) gt 0`);
                    else
                        filters.push(`(length(comment) eq 0 or comment eq null)`);
                }

                if (confidential && searchParams.includeOthers === false) {
                    if (confidential === 'With Confidential Comments')
                        filters.push(`length(confidential) gt 0`);
                    else
                        filters.push(`(length(confidential) eq 0 or confidential eq null)`);
                }

                if (submittedFrom) {
                    var fromDate = new Date(submittedFrom).toISOString();
                    filters.push(`submittedDate ge ${fromDate}`);
                }

                if (submittedTo) {
                    var toDate = new Date(submittedTo);
                    toDate.setDate(toDate.getDate() + 1);
                    filters.push(`submittedDate lt ${toDate.toISOString()}`);
                }

                if (userPersonId) {
                    filters.push(`appraiseePersonId ne ${userPersonId}`);
                }
                
                return filters.join(' and ');
            }
        }

        /**
         * Assemble and initiate CSV file download. 
         * @param {object[]} data The CSV row data.
         * @param {string} filename The filename to give the download.
         */
        function saveCsv(data, filename) {
            const BOM = '\uFEFF';
            const csv = BOM + Papa.unparse(data, { header: true });
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(blob, filename);
        }
    }

}(angular.module('evaluatorFollowUp')));