(function () {
    var app = angular.module('root', [
        // DEPENDENCIES
        // New apps MUST be added here

        'angularUtils.directives.uiBreadcrumbs',
        'assignment',
        'collegeDirectory',
        'common',
        'contacts',
        'dashboard',
        'evaluatorFollowUp',
        'groupsAndRoles',
        'messages',
        'nomination',
        'npc',
        'organization',
        'oss',
        'person',
        'pevApp',
        'program',
        'programAudit',
        'report',
        'review',
        'rfr',
        'readinessReview',
        'security',
        'statement',
        'training',
        'ui.router',
        'user',
        'userReview',
        'volunteer'
    ]);

    // IF A BREADCRUMB BREAKS
    // Check whether the returned data from a resolve is wrapped in a 'value' object

    var registerRoutes = function ($stateProvider, $urlRouterProvider) {
        var dashboardUrl = '/Apps/dashboard/templates/dashboard.html';
        var dashboardCtrl = 'dashboardCtrl';
        var getLoggedOutView = function (url) {
            return {
                loggedOut: {
                    templateUrl: url
                }
            };
        };

        $stateProvider
            .state('user', {
                url: '/user',
                templateUrl: dashboardUrl,
                controller: dashboardCtrl + ' as model',
                authenticate: true,
                permittedTasks: 'user',
                data: { displayName: 'Dashboard' }
            })
            .state('root', {
                url: '/',
                params: {
                    loginStatus: null,
                    successMsg: null,
                    errorMsg: null
                },
                views: getLoggedOutView('/Apps/root/templates/login.html'),
                authenticate: false,
                data: { displayName: false }
            })
            .state('logout', {
                url: '/',
                params: {
                    loginStatus: null,
                    successMsg: null,
                    errorMsg: null
                },
                views: getLoggedOutView('/Apps/root/templates/login.html'),
                authenticate: false,
                data: { displayName: false }
            })
            .state('movedPages', {
                url: '/moved',
                views: getLoggedOutView('/Apps/root/templates/moved.html'),
                authenticate: false,
                data: { displayName: false, pageTitle: 'Moved Pages' }
            })
            .state('error', {
                templateUrl: '/Apps/common/templates/misc/errorRedirect.html',
                authenticate: false,
                data: { displayName: false, pageTitle: 'Error' },
                params: {
                    errorCode: null,
                    errorMessage: null
                }
            })
            .state('saml', {
                url: '/saml',
                authenticate: true,
                controller: 'ssoCtrl'
            })
            .state('ssoWorkspace', {
                url: '/sso/workspace',
                authenticate: true,
                params: { accessTo: 'workspace' },
                controller: 'ssoCtrl'
            })
            .state('ssoD2l', {
                url: '/sso/d2l',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'd2l' },
                data: { displayName: false }
            })
            .state('ssoBoardEffect', {
                url: '/sso/boardeffect',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'boardeffect' },
                data: { displayName: false }
            })
            .state('ssoAmsrpt', {
                url: '/sso/amsrpt',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'amsrpt' },
                data: { displayName: false }
            })
            .state('ssoAbetWp', {
                url: '/sso/abetpublic',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'abetpublic' },
                data: { displayName: false }
            })
            .state('ssoAbetConcur', {
                url: '/sso/concur',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'concur' },
                data: { displayName: false }
            })
            .state('ssoLogout', {
                url: '/sso/logout',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'logout' },
                data: { displayName: false }
            })
            .state('ssoClose', {
                url: '/sso/close',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'close' },
                data: { displayName: false }
            })
            .state('ssoExternal', {
                url: '/sso/external',
                templateUrl: '/Apps/security/templates/ssoRedirect.html',
                controller: 'ssoCtrl',
                authenticate: true,
                params: { accessTo: 'external' },
                data: { displayName: false }
            })
            .state('readinessReviewRequest', {
                url: '/readinessrequest',
                views: getLoggedOutView('/Apps/readinessReview/templates/request.html'),
                authenticate: false,
                data: { displayName: false, pageTitle: 'Readiness Request' }
            })
            .state('createAccount', {
                url: '/createaccount?type',
                views: getLoggedOutView('/Apps/pevApp/templates/createAccount.html'),
                authenticate: false,
                data: { displayName: false, pageTitle: 'Create Account' }
            })
            .state('pevAppReference', {
                url: '/pevreference',
                views: getLoggedOutView('/Apps/pevApp/templates/reference.html'),
                authenticate: false, //true?
                //permittedTasks: 'reference',
                data: { displayName: false, pageTitle: 'PEV Reference' }
            })
            .state('pevAppraisal', {
                url: '/appraisal',
                views: getLoggedOutView('/Apps/userReview/templates/appraisalForm.html'),
                authenticate: false,
                data: { displayName: false, pageTitle: 'PEV Appraisal' }
            })
            .state('messages', {
                url: '/common/person/:personId/messages',
                templateUrl: '/Apps/message/templates/messages.html',
                controller: 'messagesCtrl as model',
                authenticate: true,
                data: { displayName: 'Messages / {{ person.firstName + " " + person.lastName}}', pageTitle: 'Messages' },
                resolve: {
                    person: function ($stateParams, personSvc) {
                        return personSvc.getPersonSummaryWithDesignation($stateParams.personId);
                    }
                }
            })
            .state('volunteer', {
                url: '/common/person/:personId/volunteer/:volunteerId',
                template: '<volunteer-page></volunteer-page>',
                authenticate: true,
                permittedTasks: 'admin,volunteer, manage volunteer',
                data: { displayName: 'Volunteer Details / {{ person.firstName + " " + person.lastName}}', pageTitle: 'Volunteer Details' },
                resolve: {
                    person: function ($stateParams, personSvc) {
                        return personSvc.getPersonSummaryWithDesignation($stateParams.personId);
                    }
                }
            })
            .state('volunteerList', {
                url: '/common/volunteer',
                templateUrl: '/Apps/volunteer/templates/volunteer.html',
                controller: 'volunteerCtrl as model',
                authenticate: true,
                permittedTasks: 'admin, manage volunteer',
                data: { displayName: 'Volunteer Search', pageTitle: 'Search / Volunteers' }
            })
            .state('statementTool', {
                url: '/statement/:statementId/tool?program,finding,criteria,ra',
                views: getLoggedOutView('/Apps/statement/templates/statementTool.html'),
                //controller: 'statementToolCtrl as model',
                authenticate: true,
                //permittedTasks: '',
                data: { displayName: false, pageTitle: 'Statement Editor' },
                params: { previousProgram: null }
            })
            .state('offlineStatementListing', {
                url: '/offline/statement',
                views: getLoggedOutView('/Apps/statement/templates/offline/statementListing.html'),
                //authenticate: true,
                data: { displayName: 'Statement Listing' },
                offline: true
            })
            .state('offlineStatementEditor', {
                parent: 'offlineStatementListing',
                url: '/:statementId/editor?program,finding,criteria',
                templateUrl: '/Apps/statement/templates/statementTool.html',
                authenticate: true,
                data: { displayName: 'Statement' },
                params: { previousProgram: null },
                offline: true
            })
            .state('offlineError', {
                parent: 'offlineStatementListing',
                templateUrl: '/Apps/common/templates/misc/errorRedirect.html',
                authenticate: false,
                data: { displayName: false },
                params: {
                    errorCode: null,
                    errorMessage: null
                },
                offline: true
            })
            .state('programAuditTool', {
                url: '/programAudit/:programAuditId/program/:programId/programAuditDetail/:programAuditDetailId/tool',
                views: getLoggedOutView('/Apps/programAudit/templates/programAuditTool.html'),
                authenticate: true,
                data: {
                    displayName: false,
                    pageTitle: 'Program Audit Editor'
                },
                params: {
                    returnStateName: null, // state name specifying route to return to when leaving tool
                    returnStateParams: null, // optional state params for return route 
                }
            })
            .state('programAuditInstitutionalSummaryTool', {
                url: '/programAudit/:programAuditId/institutionalSummary/:programAuditInstitutionalSummaryId/tool',
                views: getLoggedOutView('/Apps/programAudit/templates/programAuditInstitutionalSummaryTool.html'),
                authenticate: true,
                data: {
                    displayName: false,
                    pageTitle: 'Program Audit Institutional Summary Editor'
                },
                params: {
                    returnStateName: null, // state name specifying route to return to when leaving tool
                    returnStateParams: null, // optional state params for return route 
                }
            })
            .state('npcEditorTool', {
                url: '/npc/:npcId/report/:npcProgramChangeReportId/tool',
                views: getLoggedOutView('/Apps/npc/templates/npcEditorTool.html'),
                authenticate: true,
                permittedTasks: 'admin, npc report',
                data: {
                    displayName: false,
                    pageTitle: 'NPC Editor'
                },
                params: {
                    returnStateName: null,      // state name specifying route to return to when leaving tool
                    returnStateParams: null,    // optional state params for return route 
                    npc: null,                  // npc data, if available--else will reload
                    organization: null,         // currentOrganizationDetailDto, if available--else will reload
                    programReview: null         // program review (to determine last action), if available--else will reload
                }
            })
             .state('user.organization', {
                parent: 'user',
                url: '/organization',
                templateUrl: '/Apps/organization/templates/userOrganization.html',
                authenticate: true,
                permittedTasks: 'organization',
                allowReadOnly: true,
                data: { displayName: 'Organization', pageTitle: 'Organizations' }
            })
            .state('organization.detail', {
                parent: 'user',
                url: '/organization/:organizationId',
                templateUrl: '/Apps/organization/templates/userOrganization.html',
                authenticate: true,
                permittedTasks: 'organization',
                allowReadOnly: true,
                data: { displayName: 'Organization [{{ organization.value[0].currentOrganizationDetailDto.organizationName }}]', pageTitle: 'Organization' },
                resolve: {
                    organization: function ($stateParams, organizationSvc) {
                        return organizationSvc.getOrganizationByUser($stateParams.organizationId);
                    }
                }
            })
            .state('nomination', {
                parent: 'user',
                url: '/nomination',
                templateUrl: '/Apps/nomination/templates/societyNomination.html',
                controller: 'nominationSocietyCtrl as model',
                authenticate: true,
                permittedTasks: 'commissioner nomination',
                data: { displayName: 'Commissioner Nomination' }
            })
            .state('nominationCommittee', {
                parent: 'user',
                url: '/nominationCommittee',
                templateUrl: '/Apps/nomination/templates/committeeNomination.html',
                controller: 'nominationCommitteeCtrl as model',
                authenticate: true,
                permittedTasks: 'user, commissioner nomination',
                allowReadOnly: true,
                data: { displayName: 'Commissioner Nomination' }
            })
            .state('myReports', {
                parent: 'user',
                url: '/myreports',
                templateUrl: '/Apps/report/templates/myReport.html',
                controller: 'myReportCtrl as model',
                authenticate: true,
                permittedTasks: 'report',
                data: { displayName: 'My Reports' }
            })
            .state('reportDetail', {
                url: '/user/myreports/:reportName',
                views: getLoggedOutView('/Apps/report/templates/reportDetail.html'),
                authenticate: true,
                permittedTasks: 'report',
                data: { displayName: false, pageTitle: 'Report' }
            })
            .state('pevApp', {
                parent: 'user',
                url: '/pevapplications',
                templateUrl: '/Apps/pevApp/templates/pevApps.html',
                controller: 'pevAppsCtrl as model',
                authenticate: true,
                //permittedTasks: ,
                data: { displayName: 'PEV Applications' }
            })
            .state('pevApp.detail', {
                parent: 'pevApp',
                url: '/:volunteerApplicationId?view',
                templateUrl: '/Apps/pevApp/templates/pevAppDetail.html',
                authenticate: true,
                //permittedTasks: ,
                data: { displayName: '{{application.volunteerApplicationId}} [{{application.submittedDate ? "Submitted" : "Waiting for Submission"}}]', pageTitle: 'PEV Application' },
                resolve: {
                    application: function ($stateParams, pevAppSvc) {
                        return pevAppSvc.getApplicationByVolunteerApplicationId(parseInt($stateParams.volunteerApplicationId));
                    }
                }
            })
            .state('contacts', {
                parent: 'user',
                url: '/contacts',
                templateUrl: '/Apps/contacts/templates/contacts.html',
                authenticate: true,
                permittedTasks: 'admin, contacts',
                data: { displayName: 'Manage Contacts', pageTitle: 'Contacts' }
            })
            .state('contacts.detail', {
                parent: 'user',
                url: '/organization/:organizationId/contacts',
                templateUrl: '/Apps/contacts/templates/manageContacts.html',
                authenticate: true,
                permittedTasks: 'admin, contacts',
                data: { displayName: 'Manage Contacts [{{ organization.value[0].currentOrganizationDetailDto.organizationName }}]', pageTitle: 'Manage Contacts' },
                resolve: {
                    organization: function ($stateParams, organizationSvc) {
                        return organizationSvc.getOrganizationByUser($stateParams.organizationId);
                    }
                }
            })
            .state('profile', {
                parent: 'user',
                url: '/profile/:personId',
                templateUrl: '/Apps/user/templates/profile.html',
                authenticate: true,
                data: { displayName: 'Profile [{{profile.firstName}} {{profile.lastName}}]', pageTitle: 'Profile' },
                resolve: {
                    profile: function (currentUser) {
                        return currentUser.profile;
                    }
                }
            })
            .state('rr', {
                parent: 'user',
                url: '/readiness',
                templateUrl: '/Apps/readinessReview/templates/readinessReview.html',
                controller: 'rrCtrl as model',
                authenticate: true,
                permittedTasks: 'readiness',
                data: { displayName: 'Readiness Reviews' }
            })
            .state('rr.detail', {
                parent: 'rr',
                url: '/:readinessId?view',
                templateUrl: '/Apps/readinessReview/templates/readinessReviewDetail.html',
                authenticate: true,
                permittedTasks: 'readiness',
                data: { displayName: '{{rr.organizationName}}', pageTitle: 'Readiness Review' },
                resolve: {
                    rr: function ($stateParams, readinessSvc) {
                        return readinessSvc.getRRById($stateParams.readinessId).then(function (data) {
                            var orgData = JSON.parse(data.organizationJson);
                            return { organizationName: orgData.organizationName }
                        });
                    }
                }
            })
            .state('npcUser', {
                parent: 'user',
                url: '/programchanges',
                templateUrl: '/Apps/npc/templates/npcUser.html',
                controller: 'npcUserCtrl as model',
                authenticate: true,
                permittedtasks: 'institution',
                data: { displayName: 'Notification of Program Changes' }
            })
            .state('npcReviewer', {
                parent: 'user',
                url: '/programchangesReviewer',
                templateUrl: '/Apps/npc/templates/npcReviewer.html',
                controller: 'npcReviewerCtrl as model',
                authenticate: true,
                permittedTasks: 'npc, npc report',
                data: { displayName: 'Notification of Program Changes' }
            })
            .state('npcReviewer.detail', {
                parent: 'npcReviewer',
                url: '/:npcId/orgId/:organizationId?view',
                templateUrl: '/Apps/npc/templates/npcDetail.html',
                controller: 'npcDetailCtrl as model',
                authenticate: true,
                permittedTasks: 'npc, npc report',
                data: { displayName: 'NPC Details' }
            })
            .state('npcUser.programchanges', {
                parent: 'npcUser',
                url: '/organizationId/:organizationId',
                templateUrl: '/Apps/npc/templates/npcUser.html',
                controller: 'npcUserCtrl as model',
                authenticate: true,
                permittedTasks: 'npc',
                data: { displayName: 'Notification of Program Changes' }
            })
            .state('npcUser.detail', {
                parent: 'npcUser',
                url: '/:npcId/orgId/:organizationId?view',
                templateUrl: '/Apps/npc/templates/npcDetail.html',
                controller: 'npcDetailCtrl as model',
                authenticate: true,
                permittedTasks: 'npc',
                data: { displayName: 'NPC Details' },
                params: {
                    isInstitutionUser: false
                }
            })
            .state('userReview', {
                parent: 'user',
                url: '/review',
                templateUrl: '/Apps/userReview/templates/reviews.html',
                controller: 'userReviewCtrl as model',
                authenticate: true,
                permittedTasks: 'review, rfr',
                data: { displayName: 'Reviews' }
            })
            .state('userReview.detail', {
                parent: 'userReview',
                url: '/:reviewTeamId?view',
                templateUrl: '/Apps/userReview/templates/reviewDetail.html',
                authenticate: true,
                permittedTasks: 'review, rfr, admin',
                data: { displayName: '{{review.reviewYear}} {{review.commissionName}} {{review.reviewTypeName}}', pageTitle: 'Review' }, //'{{review.value[0].reviewDto.reviewYear}} {{review.value[0].commissionName}} {{review.value[0].reviewTypeName}}' },
                params: {
                    reviewTeamId: '',
                    currentUserReviewTeam: {},
                    organizationId: 0
                },
                resolve: {
                    review: function ($stateParams, userReviewSvc, oauth) {
                        // Note: adjuncts will need admin current review team but should have admin role since adjuncts are staff
                        return oauth.isAdmin() ?
                            userReviewSvc.getCurrentReviewTeamAdmin($stateParams.reviewTeamId) :
                            userReviewSvc.getCurrentReviewTeam($stateParams.reviewTeamId);
                    }
                }
            })
            .state('rfe', {
                parent: 'userReview',
                url: '/organization/:organizationId/rfe/:rfrId?view',
                templateUrl: '/Apps/rfr/templates/rfrDetail.html',
                authenticate: true,
                permittedTasks: 'admin, rfr',
                data: { displayName: 'RFE [{{ organization.value[0].currentOrganizationDetailDto.organizationName }}, {{rfe.reviewYear - 1}}–{{rfe.reviewYear.toString().substr(2)}}]', pageTitle: 'RFE' },
                resolve: {
                    rfe: function ($stateParams, rfrSvc) {
                        return rfrSvc.getRfrSubmissionAndCommissions($stateParams.rfrId);
                    },
                    organization: function ($stateParams, organizationSvc) {
                        return organizationSvc.getOrganizationByUser($stateParams.organizationId);
                    }
                }
            })
            .state('rfeSummary', {
                parent: 'userReview',
                url: '/organization/:organizationId/rfe/:rfrId/summary',
                templateUrl: '/Apps/rfr/templates/rfrSubmittedSummary.html',
                authenticate: true,
                permittedTasks: 'admin, rfr',
                data: { displayName: 'RFE Summary [{{ organization.value[0].currentOrganizationDetailDto.organizationName }}, {{rfe.reviewYear - 1}}–{{rfe.reviewYear.toString().substr(2)}}]', pageTitle: 'RFE Summary' },
                resolve: {
                    rfe: function ($stateParams, rfrSvc) {
                        return rfrSvc.getRfrSubmissionAndCommissions($stateParams.rfrId);
                    },
                    organization: function ($stateParams, organizationSvc) {
                        return organizationSvc.getOrganizationByUser($stateParams.organizationId);
                    }
                }
            })
            .state('tcAssignment', {
                parent: 'user',
                url: '/tcassignment',
                templateUrl: '/Apps/assignment/templates/tc/tcAssignment.html',
                controller: 'tcAssignmentCtrl as model',
                authenticate: true,
                permittedTasks: 'tc assignment',
                data: { displayName: 'Team Chair and Editor Assignment' }
            })
            .state('tcAssignmentTool', {
                url: '/user/tcassignment/tool',
                views: getLoggedOutView('/Apps/assignment/templates/tc/tcAssignmentTool.html'),
                authenticate: true,
                permittedTasks: 'tc assignment',
                data: { displayName: false, pageTitle: 'TC Assignment Tool' }
            })
            .state('editorAssignmentTool', {
                url: '/user/tcassignment/editor-tool/:type',
                views: getLoggedOutView('/Apps/assignment/templates/tc/editorAssignmentTool.html'),
                authenticate: true,
                permittedTasks: 'tc assignment',
                data: { displayName: false, pageTitle: 'Editor Assignment Tool' }
            })
            .state('pevAssignment', {
                parent: 'user',
                url: '/pevassignment',
                templateUrl: '/Apps/assignment/templates/pev/pevAssignment.html',
                controller: 'pevAssignmentCtrl as model',
                authenticate: true,
                permittedTasks: 'pev assignment',
                data: { displayName: 'PEV Assignment' }
            })
            .state('pevAssignment.summary', {
                parent: 'pevAssignment',
                url: '/society/:societyId',
                templateUrl: '/Apps/assignment/templates/pev/pevAssignment.html',
                controller: 'pevAssignmentCtrl as model',
                authenticate: true,
                permittedTasks: 'pev assignment',
                data: { displayName: '{{ organization.currentOrganizationDetailDto.organizationName }}', pageTitle: 'PEV Assignment Summary' },
                resolve: {
                    organization: function ($stateParams, organizationSvc) {
                        return organizationSvc.getOrganizationById($stateParams.societyId);
                    }
                }
            })
            .state('pevAssignmentTool', {
                url: '/user/pevassignment/society/:societyId/tool',
                views: getLoggedOutView('/Apps/assignment/templates/pev/pevAssignmentTool.html'),
                authenticate: true,
                permittedTasks: 'pev assignment',
                data: { displayName: false, pageTitle: 'PEV Assignment Tool' }
            })
            .state('training', {
                parent: 'user',
                url: '/training',
                templateUrl: '/Apps/training/templates/training.html',
                controller: 'trainingCtrl as model',
                authenticate: true,
                permittedTasks: 'admin, manage training',
                data: { displayName: 'Training' }
            })
            .state('training.pevc', {
                parent: 'training',
                url: '/pevc?view',
                templateUrl: '/Apps/training/templates/pevcTraining.html',
                controller: 'pevcTrainingCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'PEVC Training' },
                params: { training: null }
            })
            .state('training.other', {
                parent: 'training',
                url: '/other/:id',
                templateUrl: '/Apps/training/templates/otherTraining.html',
                controller: 'otherTrainingCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Other Training' },
                params: { training: null }
            })
            .state('admin', {
                url: '/admin',
                templateUrl: dashboardUrl,
                controller: dashboardCtrl + ' as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Dashboard' }
            })
            .state('person', {
                parent: 'admin',
                url: '/person',
                templateUrl: '/Apps/person/templates/person.html',
                controller: 'personCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Person', pageTitle: 'Search / People' }
            })
            .state('person.single', {
                parent: 'person',
                url: '/:personId',
                templateUrl: '/Apps/person/templates/personDetails.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: '{{ person[0].firstName }} {{ person[0].middleName }} {{ person[0].lastName }}', pageTitle: 'Person' },
                resolve: {
                    person: function ($stateParams, personSvc) {
                        var id = parseInt($stateParams.personId);
                        return personSvc.getPersonSummary(id);
                    }
                }
            })
            .state('organization', {
                parent: 'admin',
                url: '/organization',
                templateUrl: '/Apps/organization/templates/organization.html',
                controller: 'organizationCtrl as model',
                authenticate: true,
                allowReadOnly: true,
                permittedTasks: 'admin,organization',
                data: { displayName: 'Organization', pageTitle: 'Search / Organizations' }
            })
            .state('organization.single', {
                parent: 'organization',
                url: '/:organizationId',
                templateUrl: '/Apps/organization/templates/organizationDetail.html',
                controller: 'organizationDetailsCtrl as model',
                authenticate: true,
                permittedTasks: 'admin,organization',
                allowReadOnly: true,
                data: { displayName: '{{ organization.currentOrganizationDetailDto.organizationName }}', pageTitle: 'Organization' },
                resolve: {
                    organization: function ($stateParams, organizationSvc) {
                        return organizationSvc.getOrganizationById($stateParams.organizationId);
                    }
                }
            })
            .state('groupsAndRoles', {
                parent: 'organization.single',
                url: '/roles',
                templateUrl: '/Apps/groupsAndRoles/templates/groupsAndRoles.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'User Groups and Roles', pageTitle: 'User Groups and Roles' }
            })
            .state('programs', {
                parent: 'organization.single',
                url: '/programs',
                templateUrl: '/Apps/program/templates/program.html',
                controller: 'programCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Programs', pageTitle: 'Programs' }
            })
            .state('programs.detail', {
                parent: 'programs',
                url: '/:programDetailId',
                templateUrl: '/Apps/program/templates/program.html',
                controller: 'programCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: '{{ program.value[0].programName }}', pageTitle: 'Program' },
                resolve: {
                    program: function ($stateParams, programSvc) {
                        return programSvc.getProgramByDetailId($stateParams.programDetailId);
                    }
                },
                params: { isCurrent: true }
            })
            .state('reviews', {
                parent: 'organization.single',
                url: '/reviews',
                templateUrl: '/Apps/review/templates/review.html',
                controller: 'reviewCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Reviews' }
            })
            .state('reviews.detail', {
                parent: 'reviews',
                url: '/:reviewTeamId',
                templateUrl: '/Apps/review/templates/review.html',
                controller: 'reviewCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: '{{ review.value[0].commissionName }} {{ review.value[0].reviewTypeName }}', pageTitle: 'Review' },
                resolve: {
                    review: function ($stateParams, reviewTeamSvc) {
                        return reviewTeamSvc.getReviewByReviewTeamId($stateParams.reviewTeamId);
                    }
                }
            })
            .state('npc', {
                parent: 'admin',
                url: '/npc',
                templateUrl: '/Apps/npc/templates/npcAdmin.html',
                controller: 'npcAdminCtrl as model',
                authenticate: true,
                permittedTasks: 'admin, npc report',
                data: { displayName: 'Notification of Program Changes (Admin)' }
            })
            .state('npc.detail', {
                parent: 'npc',
                url: '/:npcId/orgId/:organizationId?view',
                templateUrl: '/Apps/npc/templates/npcDetail.html',
                controller: 'npcDetailCtrl as model',
                authenticate: true,
                permittedTasks: 'admin, npc report',
                data: { displayName: 'NPC Details' }
            })
            .state('collegeDirectory', {
                parent: 'admin',
                url: '/collegedirectory',
                templateUrl: '/Apps/collegeDirectory/templates/collegeDirectory.html',
                controller: 'collegeDirectoryCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'College Directory', pageTitle: 'Search / College Directory' }
            })
            .state('collegeDirectory.pending', {
                parent: 'collegeDirectory',
                url: '/pending',
                templateUrl: '/Apps/collegeDirectory/templates/pendingVerification.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Pending Verification' }
            })
            .state('collegeDirectory.single', {
                parent: 'collegeDirectory',
                url: '/:collegeDirectoryId',
                templateUrl: '/Apps/collegeDirectory/templates/collegeDirectoryDetail.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: '{{ collegeName }}', pageTitle: 'College Directory' },
                resolve: {
                    collegeName: function ($stateParams, collegeDirectorySvc) {
                        var collegeDirectoryId = parseInt($stateParams.collegeDirectoryId);
                        if (collegeDirectoryId === 0) {
                            return "New College Directory";
                        }
                        return collegeDirectorySvc.getCollegeDiretoryById($stateParams.collegeDirectoryId)
                            .then(function (data) {
                                return data.collegeName;
                            });
                    }
                }
            })
            .state('rrAdmin', {
                parent: 'admin',
                url: '/readiness',
                templateUrl: '/Apps/readinessReview/templates/rrSearch.html',
                controller: 'rrSearchCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Readiness Admin', pageTitle: 'Search / Readiness Reviews' }
            })
            .state('rrAdmin.manage', {
                parent: 'rrAdmin',
                url: '/:readinessId?view', //organization/:organizationId?view',
                templateUrl: '/Apps/readinessReview/templates/readinessReviewDetail.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: '{{rr.organizationName}}', pageTitle: 'Manage Readiness Review' },
                resolve: {
                    rr: function ($stateParams, readinessSvc) {
                        return readinessSvc.getRRById($stateParams.readinessId).then(function (data) {
                            var orgData = JSON.parse(data.organizationJson);
                            return { organizationName: orgData.organizationName }
                        });
                    }
                }
            })
            .state('pevAppAdmin', {
                parent: 'admin',
                url: '/pevapplications',
                templateUrl: '/Apps/pevApp/templates/pevAppSearch.html',
                controller: 'pevAppSearchCtrl as model',
                authenticate: true,
                permittedTasks: 'admin, pev app manager',
                data: { displayName: 'PEV Application Admin', pageTitle: 'Search / PEV Applications' },
                params: { view: null }
            })
            .state('pevAppAdmin.manage', {
                parent: 'pevAppAdmin',
                url: '/:volunteerApplicationId?view',
                templateUrl: '/Apps/pevApp/templates/pevAppDetail.html',
                authenticate: true,
                permittedTasks: 'admin, pev app manager',
                data: {
                    displayName: '{{application.applicationJson.volunteer.firstName}} {{application.applicationJson.volunteer.lastName}} ' +
                        '[{{application.submittedDate ? "Submitted" : "Waiting for Submission"}}]', pageTitle: 'Manage PEV Application'
                },
                resolve: {
                    application: function ($stateParams, pevAppSvc) {
                        return pevAppSvc.getApplicationByVolunteerApplicationId(parseInt($stateParams.volunteerApplicationId));
                    }
                }
            })
            .state('userReviewAdmin', {
                parent: 'admin',
                url: '/review',
                templateUrl: '/Apps/userReview/templates/reviewSearch.html',
                controller: 'reviewSearchCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Review Admin', pageTitle: 'Search / Reviews' }
            })
            .state('userReviewAdmin.detail', {
                parent: 'userReviewAdmin',
                url: '/:reviewTeamId?view',
                templateUrl: '/Apps/userReview/templates/reviewDetail.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: '{{review.reviewYear}} {{review.commissionName}} {{review.reviewTypeName}}', pageTitle: 'Review' }, //'{{review.value[0].reviewDto.reviewYear}} {{review.value[0].commissionName}} {{review.value[0].reviewTypeName}}' },
                params: {
                    reviewTeamId: '',
                    currentUserReviewTeam: {},
                    organizationId: 0
                },
                resolve: {
                    review: function ($stateParams, userReviewSvc) {
                        return userReviewSvc.getCurrentReviewTeamAdmin($stateParams.reviewTeamId);
                    }
                }

            })
            .state('statementAdmin', {
                parent: 'admin',
                url: '/statement',
                templateUrl: '/Apps/statement/templates/statementAdmin.html',
                controller: 'statementAdminCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Statements', pageTitle: 'Manage Statements' }
            })
            .state('statementAdmin.send', {
                parent: 'statementAdmin',
                url: '/send',
                templateUrl: '/Apps/statement/templates/statementSend.html',
                controller: 'statementSendCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Send Statements', pageTitle: 'Search / Statements' }
            })
            .state('statementAdmin.finalAction', {
                parent: 'statementAdmin',
                url: '/finalaction',
                templateUrl: '/Apps/statement/templates/statementFinalAction.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Manage Final Action' }
            })
            .state('evaluatorFollowUp', {
                parent: 'admin',
                url: '/evaluatorfollowup',
                templateUrl: '/Apps/evaluatorFollowUp/templates/evaluatorFollowUp.html',
                controller: 'evaluatorFollowUpCtrl as model',
                authenticate: true,
                permittedTasks: 'admin,manage volunteer,excom',
                data: {
                    displayName: 'Evaluator Follow-Up',
                    pageTitle: 'Evaluator Follow-up'
                }
            })
            .state('nominationAdmin', {
                parent: 'admin',
                url: '/nomination',
                templateUrl: '/Apps/nomination/templates/adminNomination.html',
                controller: 'nominationAdminCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'Commissioner Nomination' }
            })
            .state('rfrAdmin', {
                parent: 'admin',
                url: '/rfe',
                templateUrl: '/Apps/rfr/templates/rfrSearch.html',
                controller: 'rfrSearchCtrl as model',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: 'RFE Admin', pageTitle: 'Search / RFEs' }
            })
            .state('rfrAdmin.manage', {
                parent: 'rfrAdmin',
                url: '/:rfrId/organization/:organizationId?view',
                templateUrl: '/Apps/rfr/templates/rfrDetail.html',
                authenticate: true,
                permittedTasks: 'admin',
                data: { displayName: '{{resolvedData.organizationName}} ({{resolvedData.yearRange}})', pageTitle: 'Manage RFE' },
                resolve: {
                    resolvedData: function ($stateParams, $q, rfrSvc, organizationSvc) {
                        var rfrId = parseInt($stateParams.rfrId);
                        var organizationId = parseInt($stateParams.organizationId);

                        return $q.all([
                            rfrSvc.getRfrSubmissionAndCommissions(rfrId),
                            organizationSvc.getOrganizationById(organizationId)
                        ]).then(function (data) {
                            return {
                                yearRange: (data[0].reviewYear - 1) + '\u2013' + data[0].reviewYear,
                                organizationName: data[1].currentOrganizationDetailDto.organizationName
                            };
                        });
                    }
                }
            });
        $urlRouterProvider.otherwise(function ($injector) {
            var oauth = $injector.get('oauth');
            var state = $injector.get('$state');
            var path = $injector.get('$location').$$path;
            var currentUser = $injector.get('currentUser');

            if (currentUser.profile.loggedIn) {
                if (path.toLowerCase().endsWith('logout')) {
                    state.go('root', {
                        loginStatus: 'logout',
                        successMsg: 'You have been logged out successfully.',
                        errorMsg: null
                    });
                } else {
                    var dashboard = oauth.isAdmin() ? 'admin' : 'user';
                    state.go(dashboard);
                }
            } else {
                state.go('root');
            }
        });
    };

    app.config(registerRoutes);

    var extendExceptionHandler = function ($provide) {
        $provide.decorator("$exceptionHandler", function ($delegate, $injector) {
            return function (exception, cause) {
                var logSvc = $injector.get("logClientSvc");
                $delegate(exception, cause);

                //Possible infininite logging when client has any looping errors
                //Disable this until infinite logging issue resolved.
                //logSvc.insert(cause, exception.message, exception.stack);
            };
        });
    }

    app.config(extendExceptionHandler);

    app.run(function ($rootScope, $state, oauth, loginRedirect, alertSvc, offlineSvc,currentUser,eventSvc) {
        $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
            if (offlineSvc.isAppOffline()) {
                if (toState.name === 'error') {
                    // From offline state, show offline version of error page
                    redirectToOfflineError();
                    return;
                }

                if (!toState.offline) {
                    // While in "offline" mode, only allow transition to states designated as offline
                    redirectToOffline();
                    return;
                }
            } else {
                if (toState.offline) {
                    // Don't allow visiting offline pages directly--must go into "offline" mode firest
                    redirectToOnline();
                    return;
                }
            }
            if (toState.name == 'logout') {
                return;
            }

            if (toState.name == 'root' && (toParams.loginStatus == 'request' ||
                toParams.loginStatus == 'reset' ||
                toParams.loginStatus == 'resetComplete'))
                return;

            if (toState.authenticate) {
                if (currentUser.profile.token == '')
                    event.preventDefault();
                else {
                    return;
                }
            }
            else {
                //if (currentUser.profile.token ||
                //    toState.name == 'login' ||
                //    toState.name == 'readinessReviewRequest' ||
                //    toState.name == 'createAccount')
                    return;
            }

            oauth.retrieveToken().then(function (response) {

                if (response) {
                    currentUser.set(response);
                    eventSvc.broadcast('refreshIndexCurrentInfo');
                    eventSvc.broadcast('refreshDashboardPersonInfo');
                    eventSvc.broadcast('refreshImpersonateFlag');
                }
                else
                    redirectToLogin();

                if (!currentUser.profile || currentUser.profile.token == '')
                    redirectToLogin();
                else {
                    if (toState.name == 'root' || toState.name == '/' || toState.name == '')
                        redirectToDashboard();

                    if (oauth.isPermitted(toState.permittedTasks) || oauth.isReadOnlyPermitted(toState.permittedTasks)) {
                        event.preventDefault();
                        $state.go(toState, toParams);
                        
                    }
                    else {
                        redirectToError();
                        return;
                    }
                }
            }, function () {
               if (!toState.authenticate)
                   return;

                redirectToLogin();
            });

            if (!currentUser.profile || currentUser.profile.token == '')
                return;
            

            if (toState.name.toLowerCase().contains('statementtool') || toState.name.toLowerCase().contains('programaudittool')) {
                // TODO: check condition based on something other than state that's shared among all <editor-tool> instances
                document.body.classList.add("editor-tool-body");
                document.documentElement.classList.add('editor-tool-html');
            } else {
                document.body.classList.remove("editor-tool-body");
                document.documentElement.classList.remove('editor-tool-html');
            }

            function redirectToError() {
                // Send to error 
                toParams.errorCode = "403";
                toParams.errorMessage = "Access Denied. You don't have permission to access to this page."
                $state.transitionTo("error", toParams);
                event.preventDefault();
            };

            function redirectToOfflineError() {
                // Send to offline error "state" whose ui-view is nested within the offline menu
                $state.transitionTo("offlineError", toParams);
                event.preventDefault();
            };

            function redirectToLogin() {
                loginRedirect.setProperties(toState, toParams);

                oauth.logout();
                $state.transitionTo("root");
                event.preventDefault();
            };

            function redirectToOffline() {
                if (fromState.name && fromState.name != '')
                    alertSvc.addAlertInfo("You are currently OFFLINE.");
                else
                    $state.transitionTo("offlineStatementListing");
                event.preventDefault();
            };

            function redirectToOnline() {
                if (fromState.name && fromState.name != '')
                    alertSvc.addAlertInfo("You must go offline before accessing that page.");
                else {
                    transitionToDashboard();
                }
                event.preventDefault();
            };

            function redirectToDashboard() {
                transitionToDashboard();
                alertSvc.addAlertDanger("You are unauthorized for that page.");
                event.preventDefault();
            };

            function transitionToDashboard() {
                var state;
                if (offlineSvc.isAppOffline())
                    state = "offlineStatementListing";
                else if (oauth.isAdmin())
                    state = "admin";
                else
                    state = "user";
                $state.transitionTo(state);
            }
        });
    });

    app.directive('title', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
        return {
            link: function () {
                $rootScope.$on('$stateChangeSuccess', function (event, toState) {
                    $timeout(function () {
                        $rootScope.title = (toState.data && toState.data.pageTitle) ? ('AMS / ' + toState.data.pageTitle) : (toState.data.displayName ? ('AMS / ' + toState.data.displayName) : 'AMS');
                    });
                });
            }
        };
    }]);
}());