(function (module) {

    var actionSvc = function (odataSvc, $odata) {
        var factory = {};
        var actionApi = '/Action';

        /**
         * @name getActions
         * @param {array} filterArray An array of desired action codes.
         * @returns {Promise} A promise that resolves to the action objects
         */
        factory.getActions = function (filterArray) {
            var oSvc = odataSvc.get();
            var oData = oSvc.getSource(actionApi).odata();
            if (filterArray && filterArray.length > 0) {
                var predicates = [];
                filterArray.forEach(function (actionCode) {
                    predicates.push($odata.Predicate('actionCode', actionCode));
                });
                oData = oData.filter($odata.Predicate.or(predicates));
            }

            oData.query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getPossibleActions = function (lastProgramAction) {
            switch (lastProgramAction) {
                case 'NGR':
                case 'RE':
                case 'VE':
                case 'SE':
                    return ['NGR', 'IR', 'IV', 'SCR', 'SCV', 'W', 'C', 'T'];

                case 'IR':
                    return ['IV', 'IR', 'RE', 'SCR', 'SCV', 'W', 'C', 'T'];

                case 'IV':
                    return ['IV', 'IR', 'VE', 'SCV', 'SCR', 'W', 'C', 'T'];

                case 'SCV':
                case 'SCR':
                    return ['SE', 'IR', 'IV', 'NA'];

                case 'NEW':
                    return ['NGR', 'IR', 'IV', 'NA', 'W', 'C', 'T'];
            }
        }

        return {
            getActions: factory.getActions,
            getPossibleActions: factory.getPossibleActions
        };
    };

    module.factory('actionSvc', actionSvc);

})(angular.module('statement'));