(function (module) {

    var editResponseCtrl = function ($scope, $uibModalInstance, alertSvc, response, type, helperSvc, userReviewSvc, statementStorageSvc, userReviewSvc, oauth,
        dueResponseSvc, documentSvc, statementTypeIds, reviewTypeIds, globalConstantSvc, dueResponseTypeIds, dueResponseNames, messageTemplateTypes, teamMemberTypeNames, statementTypeNamesById, reviewTeamMemberSvc, messageSvc, commissionIds, $q) {
        var model = $scope;

        model.isDataReady = false;
        model.title = 'Edit Response';
        model.response = angular.copy(response);
        model.type = type;
        model.dueResponseTypeIds = dueResponseTypeIds;
        model.data = {};
        model.data.showReplaceFile = false;
        model.data.isUploading = false;

        model.discardedFile = null;

        model.responseTypeIds = {
            NO_RESPONSE: 1,
            UPLOAD_LATER: 2,
            UPLOAD_NOW: 3
        };

        model.onUpload = function (streamId, file) {
            model.updatedResponse.stream_id = streamId;
            if (model.response?.dueResponseId && model.response?.stream_id) {
                // File was replaced, original was deleted save due response to refer to it
                dueResponseSvc.update(model.updatedResponse).then(function () {
                    alertSvc.addModalAlertSuccess("Response successfully replaced.");
                }).catch(function (error) {
                    console.log('Error Replacing Due Response Upload', error);
                    model.data.isUploading = false;
                    alertSvc.addModalAlertWarning("Response could not be replaced at this time.");
                });
            }

            model.data.showReplaceFile = false;
        }

        model.submit = function () {
            if (model.data.isInstitutionUser) {
                if (model.data.responseTypeId !== model.responseTypeIds.UPLOAD_LATER) {
                    var confirmationMsg = 'Are you sure you want to submit this response? This procedure is irreversible.';
                    alertSvc.confirm(confirmationMsg, doSubmission);
                } else {
                    doSubmission();
                }
            } else {
                doSubmission();
            }

            function doSubmission() {
                if (model.data.isResponse === false) {
                    if (model.updatedResponse && model.updatedResponse.dueResponseId) {
                        alertSvc.confirm("Changing this to no response will delete all response data for this type.", deleteResponse)

                        function deleteResponse() {
                            model.discardedFile = model.updatedResponse.stream_id;
                            model.updatedResponse.stream_id = null;
                            model.updatedResponse.responseNeeded = false;
                            model.data.isUploading = true;
                            dueResponseSvc.update(model.updatedResponse).then(function () {
                                alertSvc.addAlertSuccess("Response successfully submitted.");
                                // Need to delete all response data for each finding here                         
                                statementStorageSvc.deleteAllDueResponseData(model.type)
                                // Send email to TC
                                if (model.data.isInstitutionUser) {
                                    sendEmailToTC(model.type, model.data.isResponse);
                                }
                                if (model.discardedFile) documentSvc.deleteFile(model.discardedFile);
                                $uibModalInstance.close();
                            }).catch(onError);
                        }
                    } else {
                        var newDueResponse = {
                            dueResponseId: 0,
                            reviewTeamId: statementStorageSvc.data.currentReviewTeam.reviewTeamId,
                            responseDate: new Date(),
                            dueResponseTypeId: model.type,
                            stream_id: null,
                            responseNeeded: false,
                            note: null //TODO: FIGURE OUT WHAT NOTE IS
                        }
                        model.data.isUploading = true;
                        dueResponseSvc.create(newDueResponse).then(function () {
                            updateStatementAfterText();
                            alertSvc.addAlertSuccess("Response successfully submitted.");
                            // Send email to TC
                            if (model.data.isInstitutionUser) {
                                sendEmailToTC(model.type, model.data.isResponse);
                            }
                            if (model.updatedResponse?.stream_id) documentSvc.deleteFile(model.updatedResponse.stream_id);
                            $uibModalInstance.close();
                        }).catch(onError);
                    }
                } else if (model.updatedResponse && model.updatedResponse.dueResponseId) {
                    if (model.data.responseTypeId === model.responseTypeIds.UPLOAD_LATER) {
                        model.updatedResponse.responseNeeded = true;
                        model.discardedFile = model.updatedResponse.stream_id;
                        model.updatedResponse.stream_id = null;
                        
                        updateResponse();
                    } else {
                        model.updatedResponse.responseDate = new Date();
                        model.updatedResponse.responseNeeded = !model.data.acknowledgeOnly;
                        if (model.data.acknowledgeOnly) {
                            var doUpdateResponse = (oauth.isAdmin() && statementStorageSvc.data.statement.teamMemberTypeId !== teamMemberTypeNames.ADMIN && statementStorageSvc.data.statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY) ?
                                () => { alertSvc.confirm("<strong>Warning</strong>: This statement is currently being edited by another user.  Proceeding will delete the response data for this user.", updateResponse) } :
                                updateResponse;
                            alertSvc.confirm("Changing this to 'acknowledge upload only' will delete all response data for this type.", doUpdateResponse);
                        } else
                            updateResponse();
                    }

                    function updateResponse() {
                        model.data.isUploading = true;
                        dueResponseSvc.update(model.updatedResponse).then(function () {
                            // Need to delete all response data for each finding here 
                            let promise;
                            if (!model.updatedResponse.responseNeeded && model.updatedResponse.stream_id)
                                promise = statementStorageSvc.deleteAllDueResponseData(model.type);
                            else
                                promise = updateStatementAfterText();
                            alertSvc.addAlertSuccess("Response successfully updated.");
                            promise.then(() => {
                                finishUpdatingResponseSubmission(model.updatedResponse)
                            });
                        }).catch(onError);
                    }

                } else {
                    var newDueResponse = {
                        dueResponseId: 0,
                        reviewTeamId: statementStorageSvc.data.currentReviewTeam.reviewTeamId,
                        responseDate: new Date(),
                        dueResponseTypeId: model.type,
                        stream_id: model.updatedResponse.stream_id,
                        responseNeeded: model.data.responseTypeId === model.responseTypeIds.UPLOAD_LATER || !model.data.acknowledgeOnly,
                        note: null //TODO: FIGURE OUT WHAT NOTE IS
                    }

                    dueResponseSvc.create(newDueResponse).then(function () {
                        updateStatementAfterText().then(() => {
                            finishUpdatingResponseSubmission(newDueResponse);
                        });
                        alertSvc.addAlertSuccess("Response successfully submitted.");
                    }).catch(onError);
                }

                function updateStatementAfterText() {
                    statementStorageSvc.setDueResponseData();

                    if (statementStorageSvc.data.statement && statementStorageSvc.data.statement.statementDetailDtos)
                        return statementStorageSvc.save(statementStorageSvc.data.statement);
                    else
                        return $q.when(true);
                }

                function onError(error) {
                    console.log('Error Submitting Due Response', error);
                    model.data.isUploading = false;
                    alertSvc.addModalAlertWarning("Response could not saved at this time.");
                }
            }
        }

        function finishUpdatingResponseSubmission(dueResponse) {        
            var draftSubmittedToTC = false;

            // Note: this block might be redundant code; doesn't appear anyone can currently upload 30-day due response to a draft that hasn't already been submitted to TC/RC
            if (statementStorageSvc.data.statement && statementStorageSvc.data.statement.statementDetailDtos &&
                statementStorageSvc.data.statement.statementTypeId === statementTypeIds.DRAFT &&
                model.type === dueResponseTypeIds.THIRTYDAY &&
                dueResponse.responseNeeded) {
                // 30-day due response to a draft statement should always cause it to go back to team or report chair
                draftSubmittedToTC = true;

                var newSubmittedToTeamMemberTypeId = statementStorageSvc.data.currentReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT ? 4 : 2;

                if (statementStorageSvc.data.statement.submittedToTeamMemberTypeId !== newSubmittedToTeamMemberTypeId) {
                    statementStorageSvc.updateSubmittedToTeamMemberType(statementStorageSvc.data.statement.statementId, newSubmittedToTeamMemberTypeId);
                }
            }

            if (model.data.isInstitutionUser) {
                var isUploadingLater = !dueResponse.stream_id && model.data.isResponse === true;
                sendEmailToTC(model.type, model.data.isResponse, draftSubmittedToTC, isUploadingLater);
            }

            if (model.discardedFile) documentSvc.deleteFile(model.discardedFile);

            $uibModalInstance.close();
        }

        function sendEmailToTC(typeId, isResponse, concernResponseSubmitted, isUploadingLater) {
            var messageParams = [
                {
                    messageParameterName: 'DueResponseTypeName', messageParameterValue: dueResponseNames[typeId]
                },
                {
                    messageParameterName: 'InstitutionName', messageParameterValue:statementStorageSvc.data.currentReviewTeam.organizationName
                },
                 {
                     messageParameterName: 'Response', messageParameterValue: getResponseText(model.data.responseTypeId, concernResponseSubmitted)
                }
            ];
            
            function getResponseText(responseTypeId, concernResponseSubmitted){
                if(responseTypeId === model.responseTypeIds.NO_RESPONSE){
                    return "A response " + getStatementTypeName() + " will NOT be submitted."
                }
                if(responseTypeId === model.responseTypeIds.UPLOAD_LATER){
                    return "A response " + getStatementTypeName() + " will be submitted online within the alloted time.";
                }
                if(responseTypeId === model.responseTypeIds.UPLOAD_NOW){
                    return "A response " + getStatementTypeName() + " has been submitted online. To download and view this response, please log on to https://amsapp.abet.org/#/ and navigate to this review." + getSubmittedToTCText(concernResponseSubmitted);
                }
            }
            //Due response email has been separated from the email template sharing with 7day and post-30 day.
            function getStatementTypeName(){
                if(model.type === dueResponseTypeIds.SEVENDAY){
                    return "";
                }
                if(model.type === dueResponseTypeIds.THIRTYDAY){
                    return "to the draft statement";
                }
                if(model.type === dueResponseTypeIds.THIRTYDAYPOST){
                    return "to the draft statement";
                }
            }

            function getSubmittedToTCText(concernResponseSubmitted) {
                if (concernResponseSubmitted) {
                    return " The team chair is now required to begin the Final Statement."
                }
                else {
                    return '';
                }
            }

            var teamMembersSentTo = [];
            var tcTeamMember = model.teamMembers.find(function (member) {
                return member.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || member.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR;
            });
            teamMembersSentTo.push(tcTeamMember.personId);

            var ccdTeamMembers = [];
            if(model.type === dueResponseTypeIds.THIRTYDAY){//add ed1
                var ed1 = model.teamMembers.find(function (member) {
                    return member.teamMemberTypeId === teamMemberTypeNames.EDITOR1;
                });
                ccdTeamMembers.push(ed1.personId);
            }

            var templateForSending 
            if (model.type === dueResponseTypeIds.THIRTYDAY) {
                if (isUploadingLater)
                    templateForSending = messageTemplateTypes.STATEMENTTHIRTYDAYRESPONSEUPLOADLATER;
                else {
                    if (isResponse)
                        templateForSending = messageTemplateTypes.STATEMENTTHIRTYDAYRESPONSESUBMITTED;
                    else
                        templateForSending = messageTemplateTypes.STATEMENTTHIRTYDAYRESPONSENOTTOSUBMIT;
                }
            }else{
                templateForSending = messageTemplateTypes.STATEMENTDUERESPONSE;
            }

            if (concernResponseSubmitted) {// only when institution submitted due response and there is concern without D or W - add adjunct
                globalConstantSvc.getGlobalVariable(globalConstantSvc.nameConstants.adjunctEmail, statementStorageSvc.data.currentReviewTeam.commissionId).then(function (data) {
                    if(data && data.value)
                        teamMembersSentTo.push(data.value);
                    if (teamMembersSentTo.length > 0)
                        messageSvc.buildAndSendMessage(teamMembersSentTo, ccdTeamMembers, templateForSending, messageParams);
                });
            } else {
                if (teamMembersSentTo.length > 0)
                    messageSvc.buildAndSendMessage(teamMembersSentTo, ccdTeamMembers, templateForSending, messageParams);
            }
        }

        model.viewFile = function () {
            documentSvc.downloadDocument(model.updatedResponse.stream_id);
        };

        model.cancel = function () {
            if (model.updatedResponse.stream_id && (!model.updatedResponse.dueResponseId || !model.response.stream_id)) {
                documentSvc.deleteFile(model.updatedResponse.stream_id);
            }
            $uibModalInstance.close();
        };

        var activate = function () {
            model.data.isInstitutionUser = userReviewSvc.isInstitutionUser(statementStorageSvc.data.currentReviewTeam);
            model.typeName = dueResponseNames[model.type];
            model.data.isResponse = model.response && (model.response.responseNeeded || model.response.stream_id) ? true : false;
            model.data.acknowledgeOnly = model.response && !model.response.responseNeeded && model.response.stream_id ? true : false;

            if (model.response && model.response.responseNeeded) {
                model.data.responseTypeId = model.response.stream_id ? model.responseTypeIds.UPLOAD_NOW : model.responseTypeIds.UPLOAD_LATER;
            } else {
                model.data.responseTypeId = model.data.acknowledgeOnly ? model.responseTypeIds.UPLOAD_NOW : model.responseTypeIds.NO_RESPONSE;
            }

            model.updatedResponse = model.response ? angular.copy(model.response) : {stream_id: null, reviewTeamId: statementStorageSvc.data.currentReviewTeam.reviewTeamId};
            model.newResponse = {newResponseFile: null};//must have a child object as the mode so that the file input correctly binds

            if(model.type === dueResponseTypeIds.THIRTYDAY){
                model.thirtyDayResponseRequired = statementStorageSvc.weaknessOrDeficiencyExists();
            }
            model.isThirtyDayPost = model.type === dueResponseTypeIds.THIRTYDAYPOST;

            reviewTeamMemberSvc.getMyReviewTeamMembers(statementStorageSvc.data.currentReviewTeam.reviewTeamId).then(function (data) {
                model.teamMembers = data;
                if (Array.isArray(model.teamMembers)) {
                    model.teamMembers = model.teamMembers.filter(function (member) {
                        // Filter out members who have been end-dated (i.e. replaced)
                        return member.endDate === null || new Date(member.endDate) >= new Date();
                    });
                }
                model.isDataReady = true;
            });
            
        }();
    };

    module.controller('editResponseCtrl', editResponseCtrl);

}(angular.module('statement')));