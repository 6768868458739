(function (module) {

    var userReviewSvc = function ($http, $odataresource, currentUser, contactRoleTypes, rfrSvc, $q, $uibModal, odataSvc, eventSvc, helperSvc, reviewSvc, reviewTeamMemberSvc, teamMemberStatusTypes,
        reviewTeamSvc, teamMemberTypeNames, organizationTypes, membershipSvc, globalConstantSvc, surveyTemplateSvc, surveyTemplateTypes, amsConst, commissionAbbreviations, typeConstSvc) {
        var factory = {};
        var key = 'reviewTeamId';
        //this rfes data is to store the temporary rfes until they are filtered and manipulated and can be stored in the public data object
        var rfes = { organizations: null, rfes: null };
        var rfeStartYear = helperSvc.getAcademicYear();
        var updatedCurrentReviewsMsg = "updatedCurrentReviews";
        factory.rfes = [];

        //data for userReviews stored here
        factory.data = { rfeList: [], userReviewTeams: null, currentUserReviewTeam: null, currentReviewTeam: null, currentReview: null, currentReviewTeamPrograms: null, myReviewTeamProgram: null, userPersonDesignations: [], reviewTeamMemberTravelAgreement: null, historicalFinalStatements: null };

        factory.slugs = {
            SELFSTUDY: 'selfstudy',
            STATUS: 'status',
            TEAM: 'team',
            STATEMENT: 'statement',
            PROGRAMAUDIT: 'programaudit'
        };

        factory.getCurrentCycleReviews = function () {
            var oSvc = odataSvc.get();
            var path = '/GetCurrentReviewTeamsByPerson';

            oSvc.getSource(path, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            resolveReviewTeamsData(oSvc);

            return oSvc.getDeferred().promise.then(function (data) {
                if (factory.data.currentCycleReviews !== data) {
                    factory.data.currentCycleReviews = data;
                    eventSvc.broadcast(updatedCurrentReviewsMsg);
                } else {
                    factory.data.currentCycleReviews = data;
                }
                return data;
            });
        };

        factory.listenForCurrentCycleReviewsUpdate = function (callback, scope) {
            eventSvc.listen(callback, updatedCurrentReviewsMsg, scope);
        };

        factory.getCurrentReviewTeamAdmin = function (reviewTeamId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = '/GetReviewTeamSearchWithStatus';

            oSvc.getSource(path, key).odata()
                .filter('reviewTeamId', parseInt(reviewTeamId))
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveCurrentReviewTeam(oSvc);
            }

            return oSvc.getDeferred().promise;
        };


        factory.getCurrentReviewTeam = function (reviewTeamId, noStoredData) {
            var oSvc = odataSvc.get();
            var path = '/GetCurrentReviewTeamByReviewTeamId';
            var apiPath = oSvc.getPathWithParameter(path, 'reviewTeamId', reviewTeamId);

            oSvc.getSource(apiPath, key).odata()
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveCurrentReviewTeam(oSvc);
            }

            return oSvc.getDeferred().promise;
        };

        factory.getRfeForTeamChair = function (orgId, reviewYear) {
            return rfrSvc.getRfrSubmissionsByOrganizationId(orgId).then(function (data) {
                //filter out all the ones not for the selected review year
                if (data && data.length > 0) {
                    var filteredRfes = $.grep(data, function (rfe) {
                        return rfe.reviewYear === reviewYear;
                    });
                    var currentRfe = null;
                    if (filteredRfes && filteredRfes.length > 0)
                        currentRfe = filterRfesByRevision(filteredRfes);
                    return currentRfe;
                }
                return undefined;
            });
        };

        factory.getFinalReview = function (reviewTeamId) {
            var oSvc = odataSvc.get();
            var path = '/GetFinalReview';
            var apiPath = oSvc.getPathWithParameter(path, 'reviewTeamId', reviewTeamId);

            oSvc.getSource(apiPath, key).odata()
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getHistoricalFinalStatements = function (reviewTeamId) {
            var oSvc = odataSvc.get();
            var path = '/GetHistoricalFinalStatementsByReviewTeamId';
            var apiPath = oSvc.getPathWithParameter(path, 'reviewTeamId', reviewTeamId);

            oSvc.getSource(apiPath, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise.then(function (data) {
                return data.map(datum => {
                    datum.commissionAbbreviation = typeConstSvc.getCommissionAbbreviationById(datum.commissionId);
                    datum.commissionFullName = typeConstSvc.getCommissionFullName(datum.commissionId);
                    datum.reviewTypeName = typeConstSvc.getReviewTypeName(datum.reviewType);
                    return datum;
                });
            });
        };

        factory.getRfesForUser = function () {
            //clear RFE and Org list first
            factory.data.rfeList = [];
            factory.rfes = [];
            rfes.organizations = null;
            rfes.rfes = null;
            //set up filtering
            var userId = currentUser.profile.userId;
            var rolesIncluded = [contactRoleTypes.PRESIDENT, contactRoleTypes.DELEGATEFORPRESIDENT, contactRoleTypes.PRIMARY, contactRoleTypes.DELEGATEFORPRIMARY, contactRoleTypes.SECONDARY, contactRoleTypes.BILLING];

            //Get users organizations and then filter based on where the user is a primary or secondary contact
            var organizationsDataSource = {
                dataHolder: rfes,
                dataLocationName: 'organizations',
                svcCallback: membershipSvc.getByUserIdWithoutDesignation,
                svcCallbackArguments: [userId, true, rolesIncluded, null, true],
                odataResource: true
            };

            return helperSvc.getData([organizationsDataSource]).then(function () {
                var orgs = helperSvc.getValue(rfes.organizations);
                orgs = $.grep(orgs, function (org) {
                    return org.organizationTypeId === organizationTypes.INSTITUTION;
                });
                rfes.organizations = helperSvc.uniqueArrayByKey(orgs, 'organizationId');
                // Person may have more than one role with each institution (e.g. primary, seconday contact)
                angular.forEach(rfes.organizations, function (org) {
                    var orgRoles = orgs.filter(function (orgRole) { return orgRole.organizationId === org.organizationId; });
                    org.roles = [];
                    angular.forEach(orgRoles, function (orgRole) {
                        org.roles.push({
                            isRoleOnly: orgRole.isRoleOnly,
                            roleId: orgRole.roleId,
                            roleName: orgRole.roleName,
                            roleTypeId: orgRole.roleTypeId,
                            roleTypeName: orgRole.roleTypeName,
                            designationTypeId: orgRole.designationTypeId,
                            designationTypeName: orgRole.designationTypeName,
                            designationViewModels: angular.copy(orgRole.designationViewModels)
                        });
                        org.hasPresidentRole = orgRole.roleId === contactRoleTypes.PRESIDENT || orgRole.roleId === contactRoleTypes.DELEGATEFORPRESIDENT;
                    });
                });

                var dataSourceArray = [];

                //create and push promises for getting each orgs curent rfes                
                angular.forEach(rfes.organizations, function (org) {

                    var rfeDataSource = {
                        dataHolder: rfes,
                        dataLocationName: 'rfes',
                        svcCallback: rfrSvc.getRfrSubmissionsByOrganizationId,
                        svcCallbackArguments: [org.organizationId],
                        helperCallback: function (data) {
                            //gets all the rfrs submitted and then filter by review years and group
                            var currentCycleRfes = [];
                            var nextCyclceRfes = [];

                            factory.rfes = [...new Set(data.filter(t => t.reviewYear > rfeStartYear || (t.reviewYear >= rfeStartYear - 6 && t.rfrProcessStatusId < 6))
                                                        .sort((a, b) => a.reviewYear > b.reviewYear ? -1 : 1))];
                            
                            if (factory.rfes && factory.rfes.length > 0) {
                                angular.forEach(factory.rfes, function (rfr) {
                                    if (rfr.reviewYear > rfeStartYear && rfr.isCurrent == true) {
                                        rfr.revisions = [...new Set(data.filter(t => t.organizationId == rfr.organizationId && t.reviewYear == rfr.reviewYear &&
                                            t.rfrId != rfr.rfrId && (t.submittedTimestamp || t.revisedSeq == 0)))];
                                    }
                                });
                                //angular.mostRecent = factory.rfes[0].shift();
                            }

                            factory.rfes = factory.rfes.filter(t => t.isCurrent == true);
                        },

                        optionalCallback: function (org) {
                            //check if the rfe was saved and find based on review year and org id
                            if (factory.rfes && factory.rfes.length > 0) {
                                factory.rfes = helperSvc.getFilteredArray(factory.rfes, 'organizationId', [org.organizationId], true);
                            }
                            
                            if (factory.rfes && factory.rfes.length > 0) {

                                angular.forEach(factory.rfes, function (rfe) {
                                    rfe['organizationDetails'] = org;
                                        
                                    //filter this list if the users designation matches the rfe commission and not president
                                    if (!org.hasPresidentRole) {
                                        var usersCommissionDesignations = [];
                                        var userPersonDesignations = [];
                                        angular.forEach(org.roles, function (orgRole) {
                                            angular.forEach(orgRole.designationViewModels, function (designationViewModel) {
                                                if (orgRole.designationTypeId === 1 && usersCommissionDesignations.indexOf(designationViewModel.designationId) < 0)
                                                    usersCommissionDesignations.push(designationViewModel.designationId);
                                                if (orgRole.designationTypeId === 3 && userPersonDesignations.indexOf(designationViewModel.designationId) < 0)
                                                    userPersonDesignations.push(designationViewModel.designationId);
                                            });
                                        });

                                        //The role itself contains the designation type id and the orgUser contains the desingationId
                                        if (helperSvc.arrayContainsByPropertyValue(rfe.rfrCommissionDtos, 'commissionId', usersCommissionDesignations)) {
                                            var index = factory.rfes.indexOf(rfe);
                                            if (index > -1)
                                                factory.data.rfeList.push(factory.rfes[index]);
                                        }
                                        //now need to check person designations and if they match commission
                                        factory.data.userPersonDesignations = userPersonDesignations;
                                    } else {

                                        index = factory.data.rfeList.indexOf(rfe);
                                        if (index < 0)
                                            factory.data.rfeList.push(rfe);
                                    }
                                });
                            }
                        },
                        optionalCallbackArguments: [org]
                    };

                    //push new promise 
                    dataSourceArray.push(rfeDataSource);
                });

                return helperSvc.getData(dataSourceArray).then(function (data) {

                    if (factory.data.userPersonDesignations.length > 0) {
                        return membershipSvc.getInstitutionUserCommissionByPersonIds(factory.data.userPersonDesignations).then(function (userCommissionData) {
                            var userCommissionDataArray = userCommissionData && userCommissionData.data ? userCommissionData.data.value : [];
                            var userDelegatedCommissionIds = [];
                            if (userCommissionDataArray && userCommissionDataArray.length > 0) {
                                //MATCH current user with org and see if it matches the commission
                                for (var i = 0; i < factory.data.userPersonDesignations.length; i++) {
                                    var userPersonDesignation = factory.data.userPersonDesignations[i];
                                    userDelegatedCommissionIds = userCommissionDataArray.map(function (uc) {
                                        if (uc.personId === userPersonDesignation) {
                                            return uc.commissionId;
                                        }
                                    });
                                }
                                if (userDelegatedCommissionIds && userDelegatedCommissionIds.length > 0) {
                                    for (var x = 0; x < factory.rfes.length; x++) {
                                        var rfe = factory.rfes[x];
                                        if (helperSvc.arrayContainsByPropertyValue(rfe.rfrCommissionDtos, 'commissionId', userDelegatedCommissionIds) && factory.data.rfeList.indexOf(rfe) < 0) {
                                            factory.data.rfeList.push(rfe);
                                        }
                                    }
                                }
                            }
                        });
                    }

                });

            });
        };
        

        //there is overlap on data here with review and reviewteam
        factory.getAllDataForCurrentReview = function (reviewTeamId) {
            return reviewTeamSvc.getSingleReviewTeamById(reviewTeamId).then(function (data) {
                factory.data.currentReviewTeam = data;

                var reviewId = factory.data.currentReviewTeam.reviewId;

                var reviewDataSource = {
                    dataHolder: factory,
                    dataLocationName: 'data.currentReview',
                    svcCallback: reviewSvc.getReviewByReviewId,
                    svcCallbackArguments: [reviewId],
                    odataResource: true,
                    optionalCallback: function () {
                        // Extract commissions for joint reviews into a separate, unique list
                        factory.data.currentReviewJointOptionsCommissions = !factory.data.currentReview.reviewJointOptionDtos ?
                            [] :
                            factory.data.currentReview.reviewJointOptionDtos
                                .map(function (reviewJointOption) {
                                    return reviewJointOption.reviewJointOptionProgramDtos
                                        .map(function (reviewJointOptionProgram) {
                                            return commissionAbbreviations[reviewJointOptionProgram.commissionId];
                                        })
                                        .sort()
                                        .join(', ');
                                })
                                .reduce(function (acc, cur, idx, src) {
                                    if (src.indexOf(cur) === idx)
                                        acc.push(cur);
                                    return acc;
                                }, []);
                    }
                };
                var reviewTeamMembersDataSource = {
                    dataHolder: factory,
                    dataLocationName: 'data.currentReviewTeamPrograms',
                    svcCallback: reviewTeamMemberSvc.getMyReviewTeamMembers,
                    svcCallbackArguments: [reviewTeamId],
                    optionalCallback: function () {
                        angular.forEach(factory.data.currentReviewTeamPrograms, function (teamMember) {
                            //set current persons reviewteamProgram
                            if (teamMember.reviewTeamMemberId === factory.data.currentUserReviewTeam.reviewTeamMemberId)
                                factory.data.myReviewTeamProgram = teamMember;

                            if (teamMember.leadSocietyId !== null && teamMember.leadSocietyId !== undefined) {
                                globalConstantSvc.getGlobalVariable('OrganizationAbbreviation', teamMember.leadSocietyId).then(function (data) {
                                    teamMember.societyName = helperSvc.getValue(data);

                                });
                            } else {
                                teamMember.societyName = 'N/A';
                            }

                            teamMember.assignmentHistory = angular.fromJson(teamMember.assignmentHistory);
                        });
                    },
                    odataResource: true
                };

                var dataSourceArray = [reviewDataSource, reviewTeamMembersDataSource];

                return helperSvc.getData(dataSourceArray);
            });
        };

        factory.isInstitutionUser = function (reviewTeam) {
            var array = [];
            if (reviewTeam)
                array.push(reviewTeam);
            else
                array = factory.data.userReviewTeams;

            return helperSvc.arrayContainsByPropertyValue(array, 'teamMemberTypeName', 'Institution Contact');
        };

        factory.isVolunteer = function (reviewTeam) {
            var types = [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.COTEAMCHAIR, teamMemberTypeNames.REPORTTEAMCHAIR, teamMemberTypeNames.EDITOR1, teamMemberTypeNames.EDITOR2, teamMemberTypeNames.PEV];
            return factory.isTeamMemberType(types, reviewTeam);
        };

        factory.isChairPerson = function (reviewTeam) {
            var types = [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.COTEAMCHAIR, teamMemberTypeNames.REPORTTEAMCHAIR];
            return factory.isTeamMemberType(types, reviewTeam);
        };

        factory.isEditor = function (reviewTeam) {
            var types = [teamMemberTypeNames.EDITOR1, teamMemberTypeNames.EDITOR2];
            return factory.isTeamMemberType(types, reviewTeam);
        };

        factory.isAdjunct = function (reviewTeam) {
            var types = [teamMemberTypeNames.ADJUNCT];
            return factory.isTeamMemberType(types, reviewTeam);
        };

        factory.canViewFinalStatement = function (reviewTeam) {
            var types = [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.COTEAMCHAIR, teamMemberTypeNames.REPORTTEAMCHAIR, teamMemberTypeNames.EDITOR1, teamMemberTypeNames.EDITOR2];
            return factory.isTeamMemberType(types, reviewTeam);
        };

        factory.isTeamChair = function (reviewTeam) {
            var array = [];
            if (reviewTeam)
                array.push(reviewTeam);
            else
                array = factory.data.userReviewTeams;
            return factory.isTeamMemberType([teamMemberTypeNames.TEAMCHAIR], reviewTeam);
        };

        factory.isPEV = function (reviewTeam) {
            var array = [];
            if (reviewTeam)
                array.push(reviewTeam);
            else
                array = factory.data.userReviewTeams;
            return factory.isTeamMemberType([teamMemberTypeNames.PEV], reviewTeam);
        };

        factory.isPevOrProgramObserver = function (reviewTeam) {
            var array = [];
            if (reviewTeam)
                array.push(reviewTeam);
            else
                array = factory.data.userReviewTeams;
            return factory.isTeamMemberType([teamMemberTypeNames.PEV, teamMemberTypeNames.PROGRAMOBSERVER], reviewTeam);
        };

        factory.isTCOrRTC = function (reviewTeam) {
            var array = [];
            if (reviewTeam)
                array.push(reviewTeam);
            else
                array = factory.data.userReviewTeams;
            return factory.isTeamMemberType([teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.REPORTTEAMCHAIR], reviewTeam);
        };

        factory.getChairMembers = function (addEditors, filterOnlyApproved) {
            var types = [teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.COTEAMCHAIR, teamMemberTypeNames.REPORTTEAMCHAIR];
            var result = [];

            if (addEditors) {
                types.push(teamMemberTypeNames.EDITOR1);
                types.push(teamMemberTypeNames.EDITOR2);
            }
            result = result.concat(helperSvc.getFilteredArray(factory.data.currentReviewTeamPrograms, 'teamMemberTypeId', types, true));

            result = result.filter(function (member) {
                // Filter out members who have been end-dated (i.e. replaced)
                return member.endDate === null ||
                    helperSvc.formatDate(member.endDate, false, true).toDateString() ===
                helperSvc.formatDate(new Date(member.reviewYear, 8, 30), false, false).toDateString(); //Current TC should have Sep 30 enddate
            });

            if (filterOnlyApproved) {
                var approved = helperSvc.getArrayByFieldValue(result, 'teamMemberStatusId', teamMemberStatusTypes.ASSIGNMENTAPPROVED);
                return approved[0];
            }

            return result;
        };

        factory.coTeamChairsExist = function () {
            return helperSvc.arrayContainsByPropertyValue(factory.data.currentReviewTeamPrograms, 'teamMemberTypeId', teamMemberTypeNames.COTEAMCHAIR);
        };

        factory.getTeamPrograms = function () {
            return helperSvc.getFilteredArray(factory.data.currentReviewTeamPrograms, 'teamMemberTypeId', [teamMemberTypeNames.PEV, teamMemberTypeNames.PROGRAMOBSERVER, null], true);
        };

        //isReviewDetail updates the detail page data vs the user reviews data
        factory.updateReviewTeamMemberStatus = function (reviewTeamMember, isReviewDetail) {

            return reviewTeamMemberSvc.changeTeamMemberStatus(reviewTeamMember).then(function () {
                if (isReviewDetail)
                    return factory.getAllDataForCurrentReview(factory.data.currentReviewTeam.reviewTeamId);
                else
                    return factory.getCurrentCycleReviews();
            });
        };

        factory.isTeamMemberType = function (teamMemberTypes, optionalReviewTeam) {
            var types = teamMemberTypes;
            var array = [];

            if (optionalReviewTeam)
                array.push(optionalReviewTeam);
            else
                array = factory.data.userReviewTeams;

            if (helperSvc.arrayContainsByPropertyValue(array, 'teamMemberTypeId', types))
                return true;
            else
                return false;
        };

        //factory.getTeamChairSurvey = function () {
        //    var templateId = surveyTemplateTypes.TEAMCHAIR_EVALUATION_OF_PEV;
        //    return surveyTemplateSvc.getSurveyTemplateById(templateId);
        //};

        //factory.getSurvey = function () {
        //    var templateId = surveyTemplateTypes.PEER_EVALUATION_OF_PEV;
        //    return surveyTemplateSvc.getSurveyTemplateById(templateId);            
        //};

        function resolveReviewTeamsData(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                if (data && data.length > 0) {
                    angular.forEach(data, function (review) {
                        review.reviewProcessTracking = angular.fromJson(review.reviewProcessTracking);
                        review.previousStatement = angular.fromJson(review.previousStatement);
                        review.previousStatement = review.previousStatement && review.previousStatement.length > 0 ? review.previousStatement[0] : null;
                        review.programActionCodes = angular.fromJson(review.programActionCodes);
                    });
                }

                factory.data.userReviewTeams = data;
            });
        }

        function resolveCurrentReviewTeam(oSvc) {
            oSvc.getDeferred().promise.then(function (data) {
                factory.data.currentUserReviewTeam = data;
            });
        }

        function filterRfesByRevision(rfeArray, addRevisions) {
            //filter for most recently updated rfe
            rfeArray.sort(function (a, b) {
                return b.revisedSeq - a.revisedSeq;
            });
            var mostRecent = rfeArray[0];

            if (addRevisions) {
                mostRecent.revisions = [];

                // push the rest of the revised rfes onto the revisions array
                for (var i = 1; i < rfeArray.length; i++) {
                    mostRecent.revisions.push(rfeArray[i]);
                }
            }
            return mostRecent;
        }

        factory.submitSelfStudies = function (reviewTeamId, signedText) {
            var path = amsConst.webApiUrl + '/odata/SubmitSelfStudies';
            var selfStudySubmittedDate = new Date();
            var data = {
                reviewTeamId: reviewTeamId,
                selfStudySubmittedTimestamp: selfStudySubmittedDate.toUTCString(),
                signedText: signedText
            };

            // note: we should probably reload currentReviewTeam but at the moment we have no idea what kind of object it is or how it was loaded in the first place.
            var promise = $http.post(path, data).then(function (data) {
                if (factory && factory.data && factory.data.currentReviewTeam) {
                    factory.data.currentReviewTeam.selfStudySubmittedTimestamp = selfStudySubmittedDate;
                }
                return data;
            });

            return promise;
        };

        factory.postTravelWaiver = function (reviewTeamMemberId, typedName) {
            var path = amsConst.webApiUrl + '/odata/ReviewTeamMemberTravelAgreement';
            var data = {
                reviewTeamMemberTravelAgreementId: 0,
                reviewTeamMemberId: reviewTeamMemberId,
                answer: true,
                typedName
            };

            var promise = $http.post(path, data).then(function (data) {
                return data;
            });

            return promise;
        };

        //change this outputting 3 arrays based on the team member type
        factory.filterVisibleReviews = function (reviews, academicYear) {
            if (!reviews) { return; }
            reviews = academicYear ? helperSvc.getFilteredArray(reviews, 'reviewYear', [academicYear], true) : reviews;
            var filteredReviews = filterCycleReviewsByStatus(reviews);
            return filteredReviews;

            //if the review has not been approved by all parties dont show yet
            function filterCycleReviewsByStatus(reviews) {
                var reviewsOutput = { institutionReviews: [], teamChairAndEditorReviews: [], pevReviews: [] };
                for (var i = 0; i < reviews.length; i++) {
                    var review = reviews[i];

                    if (review.teamMemberTypeName === "Institution Contact") {
                        reviewsOutput.institutionReviews.push(review);
                    }
                    else if (review.teamMemberTypeName === "Adjunct" || review.teamMemberTypeId === teamMemberTypeNames.ADJUNCT) {
                        reviewsOutput.teamChairAndEditorReviews.push(review);
                    }
                    else if ((review.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || review.teamMemberTypeId === teamMemberTypeNames.COTEAMCHAIR || review.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR
                        || review.teamMemberTypeId === teamMemberTypeNames.EDITOR1 || review.teamMemberTypeId === teamMemberTypeNames.EDITOR2)
                        && (review.teamMemberStatusId === teamMemberStatusTypes.APPROVEDBYINSTITUTIONWAITINGFORVOLUNTEERACCEPT || review.teamMemberStatusId === teamMemberStatusTypes.ASSIGNMENTAPPROVED)) {
                        reviewsOutput.teamChairAndEditorReviews.push(review);
                    }
                    else if ((review.teamMemberTypeId === teamMemberTypeNames.PEV || review.teamMemberTypeId === teamMemberTypeNames.PROGRAMOBSERVER) && (review.teamMemberStatusId === teamMemberStatusTypes.APPROVEDBYINSTITUTIONWAITINGFORVOLUNTEERACCEPT || review.teamMemberStatusId === teamMemberStatusTypes.ASSIGNMENTAPPROVED)) {
                        reviewsOutput.pevReviews.push(review);
                    }
                }
                return reviewsOutput;
            }
        };

        factory.getReviewTeamMemberTravelAgreement = function (reviewTeamMemberId) {
            var oSvc = odataSvc.get();
            var path = '/GetAgreementByReviewTeamMemberId';
            var apiPath = oSvc.getPathWithParameter(path, 'id', reviewTeamMemberId);

            oSvc.getSource(apiPath, key).odata()
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise.then(function (data) {
                factory.data.reviewTeamMemberTravelAgreement = data;
                return data;
            });
        };

        return {
            data: factory.data,
            slugs: factory.slugs,
            getCurrentCycleReviews: factory.getCurrentCycleReviews,
            listenForCurrentCycleReviewsUpdate: factory.listenForCurrentCycleReviewsUpdate,
            getCurrentReviewTeam: factory.getCurrentReviewTeam,
            getFinalReview: factory.getFinalReview,
            getHistoricalFinalStatements: factory.getHistoricalFinalStatements,
            getAllDataForCurrentReview: factory.getAllDataForCurrentReview,
            getCurrentReviewTeamAdmin: factory.getCurrentReviewTeamAdmin,
            isInstitutionUser: factory.isInstitutionUser,
            isVolunteer: factory.isVolunteer,
            isChairPerson: factory.isChairPerson,
            isTeamChair: factory.isTeamChair,
            isPEV: factory.isPEV,
            isPevOrProgramObserver: factory.isPevOrProgramObserver,
            isEditor: factory.isEditor,
            isAdjunct: factory.isAdjunct,
            getChairMembers: factory.getChairMembers,
            getTeamPrograms: factory.getTeamPrograms,
            getRfesForUser: factory.getRfesForUser,
            isTeamMemberType: factory.isTeamMemberType,
            canViewFinalStatement: factory.canViewFinalStatement,
            updateReviewTeamMemberStatus: factory.updateReviewTeamMemberStatus,
            getRfeForTeamChair: factory.getRfeForTeamChair,
            coTeamChairsExist: factory.coTeamChairsExist,
            submitSelfStudies: factory.submitSelfStudies,
            isTCOrRTC: factory.isTCOrRTC,
            filterVisibleReviews: factory.filterVisibleReviews,
            getReviewTeamMemberTravelAgreement: factory.getReviewTeamMemberTravelAgreement,
            postTravelWaiver: factory.postTravelWaiver
        };
    };
    module.factory('userReviewSvc', userReviewSvc);

})(angular.module('userReview'));