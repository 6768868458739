(function (module) {

    /* STATEMENT directives includes the following:
        - final-action
        - go-offline
        - manage-statement
        - review-info
    */

    var templateRoot = "/Apps/statement/templates/";

    module.directive('finalAction', function ($state, $uibModal) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'widgets/finalAction.html',
            link: function (scope, ctrl, attrs) {
                scope.openFinalAction = function () {
                    $state.go('statementAdmin.finalAction');
                };

                scope.openAuditByAction = function () {
                    getReportModal("Manage Final Action - Audit by Action", []);
                };
                scope.openAuditByInstitution = function () {
                    getReportModal("Manage Final Action - Audit by Institution", []);
                };
                scope.openAuditByNGRYear = function () {
                    getReportModal("Manage Final Action - Audit by NGR Year", []);
                };
                scope.openAuditBySociety = function () {
                    getReportModal("Manage Final Action - Audit by Society", []);
                };
                scope.openAuditWithNotesByInstitution = function () {
                    getReportModal("Manage Final Action - Audit with Notes by Institution", []);
                };

                function getReportModal(reportName, params) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/report/templates/preview.html',
                        size: 'xl',
                        controller: 'previewCtrl',
                        resolve: {
                            reportName: function () { return reportName; },
                            inparams: function () { return params; }
                        }
                    });
                    return modalInstance;
                };
            }
        };
    });

    module.directive('goOffline', function ($state, $window, offlineStatementSvc, offlineSvc, statementStorageSvc, userReviewSvc, currentUser, helperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'offline/goOffline.html',
            link: function (scope, ctrl, attrs) {

                scope.data = null;
                scope.selectedReviews = [];
                //scope.reviews = [
                //    { organizationName: 'The Johns Hopkins University', teamMemberTypeName: 'Visit Team Chair', id: 123, statementId: 70 },
                //    { organizationName: 'Virginia State University', teamMemberTypeName: 'Visit Team Chair', id: 456, statementId: 71 },
                //    { organizationName: 'Alfred State College', teamMemberTypeName: 'Visit Team Chair', id: 789, statementId: 26 }
                //];

                scope.select = offlineStatementSvc.select;
                scope.getSelectedReviews = offlineStatementSvc.getSelectedReviews;
                scope.canEditOffline = offlineStatementSvc.canEditOffline;

                scope.goOffline = function () {
                    offlineStatementSvc.goToOfflineState();
                }

                var activate = function () {
                    offlineStatementSvc.clearSelectedReviews();

                    var userReviewsPromise = userReviewSvc.getCurrentCycleReviews().then(function (data) {
                        var academicYear = helperSvc.getAcademicYear();
                        var reviewsList = userReviewSvc.filterVisibleReviews(data);
                        reviewsList = reviewsList.teamChairAndEditorReviews;
                        reviewsList = reviewsList.filter(filterReviewsList);
                        scope.reviews = reviewsList;
                    });

                    function filterReviewsList(review) {
                        return offlineStatementSvc.canEditOffline(review, false); // assume isUpcoming = false though this parameter appears to never be used
                    }
                }();
            }
        };
    });

    module.directive('manageStatement', function ($state, commissionIds, statementAdminSvc, statementStatuses, helperSvc, barSvc, oauth) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'widgets/manageStatement.html',
            link: function (scope, ctrl, attrs) {
                scope.currentYear = 2018; //TODO: GET THE CURRENT YEAR FOR STATEMENTS???
                scope.dataIsReady = false;

                scope.commissionIds = commissionIds;

                scope.getDraftStatementsSent = function (commissionId) {
                    if (commissionId === commissionIds.ANSAC && scope.ANSACStatements)
                        return helperSvc.getFilteredArray(scope.ANSACStatements, 'statementStatusId', statementStatuses.SENTTOINSTITUTION, true).length;
                    if (commissionId === commissionIds.CAC && scope.CACStatements)
                        return helperSvc.getFilteredArray(scope.CACStatements, 'statementStatusId', statementStatuses.SENTTOINSTITUTION, true).length;
                    if (commissionId === commissionIds.EAC && scope.EACStatements)
                        return helperSvc.getFilteredArray(scope.EACStatements, 'statementStatusId', statementStatuses.SENTTOINSTITUTION, true).length;
                    if (commissionId === commissionIds.ETAC && scope.ETACStatements)
                        return helperSvc.getFilteredArray(scope.ETACStatements, 'statementStatusId', statementStatuses.SENTTOINSTITUTION, true).length;
                }

                var activate = function () {
                    var searchOpts = { reviewYear: 2018 };
                    statementAdminSvc.advancedSearch(searchOpts).then(function (data) {
                        scope.statements = data;
                        scope.CACStatements = filterStatementsByCommissionId(commissionIds.CAC);
                        scope.ANSACStatements = filterStatementsByCommissionId(commissionIds.ANSAC);
                        scope.EACStatements = filterStatementsByCommissionId(commissionIds.EAC);
                        scope.ETACStatements = filterStatementsByCommissionId(commissionIds.ETAC);

                        scope.dataIsReady = true;
                    });
                }();

                function filterStatementsByCommissionId(commissionId) {
                    var totalStatements = [];
                    if (scope.statements.length > 0) {
                        totalStatements = helperSvc.getFilteredArray(scope.statements, 'commissionId', commissionId, true);
                    }
                    return totalStatements;
                }


                scope.openSendStatements = function () {
                    $state.go('statementAdmin.send');
                };
            }
        };
    });

    module.directive('reviewInfo', function ($q, userReviewSvc, helperSvc, documentSvc, alertSvc, $uibModal, $state, $stateParams, evaluatorReportSvc, programReviewSvc, teamMemberTypeNames, reviewTypeIds, eventSvc, programAuditDetailSvc, programAuditSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'widgets/reviewInfo.html',
            link: function (scope, ctrl, attrs) {
                scope.currentUserReviewTeam = userReviewSvc.data.currentUserReviewTeam;
                scope.isReport = scope.currentUserReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT;
                scope.reviewYear = scope.currentUserReviewTeam.reviewYear;

                var reviewTeamId = parseInt($stateParams.reviewTeamId);

                scope.getNGRYearText = function () {
                    if (!scope.currentUserReviewTeam) return 'Not entered';
                    var ngrYear = scope.currentUserReviewTeam.ngrYear;
                    var currentAcademicYear = helperSvc.getAcademicYear();
                    if (ngrYear <= currentAcademicYear) ngrYear += 6;
                    return (ngrYear - 1) + " – " + ngrYear;
                }

                scope.formatDate = helperSvc.formatDate;

                scope.isNotTeamChair = function () {
                    return scope.currentUserReviewTeam.teamMemberTypeId !== teamMemberTypeNames.TEAMCHAIR && scope.currentUserReviewTeam.teamMemberTypeId !== teamMemberTypeNames.REPORTTEAMCHAIR;
                }

            /**
            * Opens Program Audit Form PDF
            * @param {Boolean} isPreview Whether the PDF is a preview or final deliverable
            */
                scope.openPAF = function (isPreview) {
                    programAuditSvc.getProgramAuditByReviewTeamId(scope.currentUserReviewTeam.reviewTeamId).then(function (data) {
                        var programAuditId = 0;
                        if (data && data.length > 0) {
                            programAuditId = data[data.length - 1].programAuditId;

                            programAuditDetailSvc.getAllProgramAuditToolData(programAuditId).then(function (data) {
                                var modalInstance = $uibModal.open({
                                    animation: true,
                                    templateUrl: '/Apps/programAudit/templates/modal/viewPAFPreview.html',
                                    size: 'xl',
                                    controller: 'viewPAFPreviewCtrl',
                                    resolve: {
                                        currentProgramAuditData: function () { return data; },
                                        isExitStatement: function () { return false; },
                                        isSingleProgram: function () { return false; },
                                        isInstitutionalSummary: function () { return false; },
                                        isPreview: function () { return isPreview; },
                                        showInstitutionalSummary: function () { return false; }
                                    }
                                });
                            });
                        } else {
                            alertSvc.addAlertWarning("No PAF Available")
                        }
                    });
                    
                }

                scope.onUpload = function (streamId, file) {
                    var evaluatorReport = scope.data.evaluatorReport;
                    evaluatorReport.evaluatorReportId = evaluatorReport.evaluatorReportId || 0;
                    evaluatorReport.stream_id = streamId;
                    evaluatorReport.reviewTeamId = evaluatorReport.reviewTeamId || reviewTeamId;

                    if (evaluatorReport.evaluatorReportId) {
                        evaluatorReportSvc.update(evaluatorReport).then(function () {
                            // Note: statment validation doesn't implement "invoke" pattern, local storage events are ignored
                            // when fired from same page, and watch expressions didn't work on evaluatorReportSvc.data, 
                            // leaving events as only way to communicate between directives and their host pages.
                            eventSvc.broadcast('pafUploaded');
                            alertSvc.addAlertSuccess("PAF succesfully updated")
                        });
                    } else {
                        var programs = programReviewSvc.data.programs
                        evaluatorReport.evaluatorReportDetailDtos = [];

                        angular.forEach(programs, function (program) {
                            var evaluatorReportDetail = {
                                evaluatorReportDetailId: 0,
                                programId: program.programId,
                                recommendedAction: null,
                                statementJson: null
                            }
                            evaluatorReport.evaluatorReportDetailDtos.push(evaluatorReportDetail);
                        });

                        evaluatorReportSvc.create(evaluatorReport).then(function () {
                            eventSvc.broadcast('pafUploaded');
                            alertSvc.addAlertSuccess("PAF succesfully uploaded")
                        });
                    }
                }

                var activate = function () {
                    // Make sure  data is loaded
                    var promises = [];

                    // Load current review team if not already set in parent controller
                    var currentUserReviewTeamPromise = null;
                    if (!scope.currentUserReviewTeam) {
                        var isAdmin = $state.current.name.toLowerCase().contains('admin');
                        if (isAdmin) {
                            currentUserReviewTeamPromise = userReviewSvc.getCurrentReviewTeamAdmin(reviewTeamId).then(function () {
                                scope.currentUserReviewTeam = userReviewSvc.data.currentUserReviewTeam;
                            });
                        } else {
                            currentUserReviewTeamPromise = userReviewSvc.getCurrentReviewTeam(reviewTeamId).then(function () {
                                scope.currentUserReviewTeam = userReviewSvc.data.currentUserReviewTeam;
                            });
                        }
                    }

                    promises.push(currentUserReviewTeamPromise);

                    // Load evaluator report for this review team
                    var currentEvaluatorReportPromise = evaluatorReportSvc.getEvaluatorReportByReviewTeamId(reviewTeamId).then(function () {
                        scope.data = evaluatorReportSvc.data;
                    });

                    promises.push(currentEvaluatorReportPromise);

                    // Add promises to load other data elements here...

                    $q.all([promises]);
                }();
            }
        };
    });

    module.directive('dueResponses', function ($q, $uibModal, helperSvc, documentSvc, $filter, statementFindingTypes, statementTypeIds, teamMemberTypeNames, reviewTypeIds, helperSvc, alertSvc, $state, $stateParams, statementStorageSvc, dueResponseSvc, userReviewSvc, dueResponseTypeIds, dueResponseNames, oauth, statementStatuses) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'widgets/dueResponses.html',
            scope: {},//needs its own isolated scope
            link: function (scope, ctrl, attrs) {
                scope.dueResponseTypeIds = dueResponseTypeIds;
                scope.isDataReady = false;
                scope.isStatementSubmittedToInstitution = statementStorageSvc.isStatementSubmittedToInstitution();
                scope.formatDate = helperSvc.formatDate;

                var reviewTeamId = parseInt($stateParams.reviewTeamId);

                scope.editResponse = function (type, response) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/statement/templates/modals/editResponse.html',
                        size: 'md',
                        controller: 'editResponseCtrl',
                        resolve: {
                            response: function () { return response; },
                            type: function () { return type; }
                        }
                    });
                }

                scope.deletePostResponse = function (type,response) {

                    if (statementStorageSvc.data.statement.statementTypeId === statementTypeIds.COMMISSIONEDITING) {
                        alertSvc.addAlertSuccess("Cannot be deleted. There is a Commission Statement.");
                        return;
                    }

                    alertSvc.confirm("<p>You are about to delete the post 30-day response. It will also delete post 30-day statement if exists.</p><p>Are you sure you want to proceed?</p>", deletePost30DayResponse);

                    function deletePost30DayResponse() {
                        if (statementStorageSvc.data.statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY) {
                            alertSvc.confirm("<p><strong>Warning</strong>: the post 30-day statement has already been started.  Proceeding will delete all versions of this statement.</p><p>If you want to change the post 30-day due response instead, click on 'Edit Response'</p><br/><p>Are you sure you want to proceed?</p>", doDeletePost30DayResponse);

                        } else 
                            doDeletePost30DayResponse();
                    }

                    function doDeletePost30DayResponse() {
                        dueResponseSvc.delete(response).then(function () {
                            statementStorageSvc.deletePost30Statements(response.reviewTeamId).then(function () {
                                alertSvc.addAlertSuccess("Post-30-Day response deleted.");
                            });
                        });
                    }
                }

                scope.viewFile = function (streamId) {
                    documentSvc.downloadDocument(streamId);
                };


                scope.instiutionRequestsPost30Day = function () { //create a Post-30-Day response here
                    alertSvc.confirm("<p>You are about to request a post 30-day response. Once requested the institution must submit post 30-day response materials. The final statement must be submitted to ABET before the post 30-day statement can be created.</p><p>Are you sure you want to proceed?</p>", createPost30DayResponse);

                    function createPost30DayResponse() {

                        var newDueResponse = {
                            dueResponseId: 0,
                            reviewTeamId: statementStorageSvc.data.currentReviewTeam.reviewTeamId,
                            responseDate: new Date(),
                            dueResponseTypeId: dueResponseTypeIds.THIRTYDAYPOST,
                            stream_id: null,
                            note: null,
                            responseNeeded: true
                        }

                        dueResponseSvc.create(newDueResponse).then(function () {
                            //if the statement is already final and currently with the admin, "submit" as admin
                            if (statementStorageSvc.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN && statementStorageSvc.data.statement.statementTypeId === statementTypeIds.FINAL) {
                                statementStorageSvc.submitFinalForPost30Day();
                            }
                            alertSvc.addAlertSuccess("Post-30-Day response created.");
                        });

                    }
                }

                scope.getResponseText = function (type) {
                    var dueResponse
                    switch (type) {
                        case dueResponseTypeIds.SEVENDAY:
                            dueResponse = scope.data.sevenDayResponse;
                            break;
                        case dueResponseTypeIds.THIRTYDAY:
                            dueResponse = scope.data.thirtyDayResponse;
                            break;
                        case dueResponseTypeIds.THIRTYDAYPOST:
                            dueResponse = scope.data.thirtyDayPostResponse;
                            break;
                        default:
                            dueResponse = null;
                    }

                    if (scope.daysFromVisitEnded < 0) {
                        return 'Unavailable until visit is conducted';
                    } else if (!dueResponse || dueResponse.responseNeeded === null) {
                        return 'Not Responded';
                    } else if (dueResponse.stream_id) {
                        var responseDate = $filter('date')(dueResponse.responseDate, 'M/d/yyyy');
                        return (dueResponse.responseNeeded ? 'Responded' : 'Acknowledged') + (dueResponse.responseDate ? ' on ' + responseDate : '');
                    } else if (dueResponse.responseNeeded) {
                        return 'Response Needed';
                    } else {
                        return 'No Response Needed';
                    }
                }

                scope.isTCEDORADJ = function () {
                    return scope.isTeamChair || userReviewSvc.isEditor(scope.currentUserReviewTeam) || userReviewSvc.isAdjunct(scope.currentUserReviewTeam);
                }

                var STATEMENTEDITORACCESS = {
                    USERCURRENTSTATEMENT: 1,
                    USERNOSTATEMENT: 2,
                    USERSUBMITTEDSTATEMENT: 3,
                    ADMIN: 4
                };

                scope.isCurrentUserStatement = function () {
                    return scope.getStatementAvailabilitySetting() === STATEMENTEDITORACCESS.USERCURRENTSTATEMENT;
                }

                scope.getStatementAvailabilitySetting = function () {
                    if (oauth.isAdmin())//need to check this first so that the else isnt hit ever
                    {
                        return STATEMENTEDITORACCESS.ADMIN;
                    }
                    //the current statement being edited matches the current user //2nd part is for when the statenet has not been started but still need to add a due response
                    if (statementStorageSvc.data.statement.teamMemberTypeId === statementStorageSvc.data.currentReviewTeam.teamMemberTypeId || ((statementStorageSvc.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || statementStorageSvc.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR) && statementStorageSvc.data.statement.length < 1)) {
                        return STATEMENTEDITORACCESS.USERCURRENTSTATEMENT;
                    }
                    else {
                        //user has a submitted statement
                        if (statementStorageSvc.getLatestStatementByCurrentTeamMemberType() !== null) {
                            return STATEMENTEDITORACCESS.USERSUBMITTEDSTATEMENT;
                        } else {//user has not submitted a statement and is not the current editor 
                            return STATEMENTEDITORACCESS.USERNOSTATEMENT;
                        }
                    }
                }

                scope.showSevenDayUploadBtn = function () {
                    return scope.daysFromVisitEnded >= 0 && scope.daysFromVisitEnded <= 7 && scope.isInstitutionUser && !scope.isReport;
                };

                scope.showThirtyDayUploadBtn = function () {
                    return (
                                (statementStorageSvc.data.statement.statementTypeId === statementTypeIds.FINAL &&
                                    (
                                        (scope.isTeamChair &&
                                            (statementStorageSvc.data.statement.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || 
                                             statementStorageSvc.data.statement.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR)) ||
                                        (scope.isAdmin && statementStorageSvc.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN)
                                    )
                                ) ||
                                (scope.isStatementSubmittedToInstitution && statementStorageSvc.data.statement.statementTypeId === statementTypeIds.DRAFT)
                            )
                }

                scope.beforeDueResponseDueDate = function () {
                    var responseDate = scope.getThirtyDayDueResponseDueDate();
                    var result = false;
                    if (responseDate !== 'N/A') {
                        result = helperSvc.getDateDiff(new Date(), new Date(responseDate), "days") >= -1
                    }
                    return result;
                }

                scope.showRequestPostThirtyDayBtn = function () {
                    return scope.showThirtyDayUploadBtn() && scope.beforePost30DayJulyMeetingDeadline && scope.data.thirtyDayResponse?.stream_id;
                }

                scope.showPostThirtyDayBtn = function () {
                    return  (statementStorageSvc.data.statement.statementTypeId === statementTypeIds.FINAL ||

                                (statementStorageSvc.data.statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY &&
                                ((scope.isTeamChair && 
                                    (statementStorageSvc.data.statement.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || 
                                     statementStorageSvc.data.statement.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR)) ||
                                 (scope.isAdmin && statementStorageSvc.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN)))
                        );
                }

                scope.getSevenDayDueResponseDueDate = function () {
                    if (scope.currentUserReviewTeam.visitEndDate) {
                        var daysToAdd = 7;
                        var endDate = new Date(scope.currentUserReviewTeam.visitEndDate);
                        var resultSeconds = endDate.getTime() + (1000 * 60 * 60 * 24 * daysToAdd);
                        return new Date(resultSeconds);
                    } else {
                        return 'N/A';
                    }

                }

                scope.getThirtyDayDueResponseDueDate = function () {
                    if (scope.draftSubmitted) {
                        var daysToAdd = 30;
                        var endDate = new Date(scope.draftSubmitted.submittedTimestamp);
                        var resultSeconds = endDate.getTime() + (1000 * 60 * 60 * 24 * daysToAdd);
                        return new Date(resultSeconds);
                    } else {
                        return 'N/A';
                    }

                }

                scope.getPostThirtyDayDueResponseDueDate = function () {
                    return 'N/A';
                }
                scope.isPostThirtyRequested = function () {
                    return scope.data.dueResponses.some(t => t.dueResponseTypeId === 4);
                }

                var activate = function () {
                    // Make sure  data is loaded
                    var promises = [];

                    // Load current review team if not already set in parent controller
                    var currentUserReviewTeamPromise = null;
                    if (!scope.currentUserReviewTeam) {
                        if (oauth.isAdmin() || oauth.isAdjunct()) {
                            currentUserReviewTeamPromise = userReviewSvc.getCurrentReviewTeamAdmin(reviewTeamId).then(function () {
                                scope.currentUserReviewTeam = userReviewSvc.data.currentUserReviewTeam;
                            });
                        } else {
                            currentUserReviewTeamPromise = userReviewSvc.getCurrentReviewTeam(reviewTeamId).then(function () {
                                scope.currentUserReviewTeam = userReviewSvc.data.currentUserReviewTeam;
                            });
                        }
                    }
                    promises.push(currentUserReviewTeamPromise);

                    var dueResponsePromise = dueResponseSvc.getDueResponsesByReviewTeamId(reviewTeamId);
                    promises.push(dueResponsePromise);

                    $q.all(promises).then(function () {
                        scope.currentUserReviewTeam = userReviewSvc.data.currentUserReviewTeam;
                        scope.noShowSevenDay = scope.currentUserReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT;
                        scope.isReport = scope.currentUserReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT || scope.currentUserReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMVISIT;
                        scope.isInstitutionUser = userReviewSvc.isInstitutionUser(scope.currentUserReviewTeam);
                        scope.isTeamChair = userReviewSvc.isTCOrRTC(scope.currentUserReviewTeam);
                        scope.isAdmin = oauth.isAdmin();
                        var currentDate = new Date((new Date()).toDateString()); // Get current date at 00:00:00.0 UTC
                        scope.daysFromVisitEnded = scope.currentUserReviewTeam.visitEndDate ? helperSvc.getDateDiff(scope.currentUserReviewTeam.visitEndDate, currentDate, "days") : 0;
                        scope.nonIVProgramExist = false;
                        //If IV review check for Non IV programs
                        if (scope.currentUserReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMVISIT) {
                            var nonIVPrograms = statementStorageSvc.getIVProgramsNotIVReviewType();
                            scope.nonIVProgramExist = nonIVPrograms.length > 0;
                        }

                        if (statementStorageSvc.data && statementStorageSvc.data.statementHistory && statementStorageSvc.data.statementHistory.length > 0) {
                            scope.draftSubmitted = statementStorageSvc.data.statementHistory.find(statement =>
                                statement.statementTypeId === statementTypeIds.DRAFT &&
                                (statement.statementStatusId === statementStatuses.SENTTOINSTITUTION || statement.statementStatusId === statementStatuses.RESENTTOINSTITUTION)
                            );
                            if (scope.draftSubmitted) {
                                scope.thirtyDayResponseNeeded = statementStorageSvc.weaknessOrDeficiencyExists();
                                scope.beforePost30DayMaySubmissionDeadline = helperSvc.getDateDiff(currentDate, new Date(scope.currentUserReviewTeam.reviewYear, 05, 01), "days") > 0; //closed on may 31
                                scope.beforePost30DayJulyMeetingDeadline = helperSvc.getDateDiff(currentDate, new Date(scope.currentUserReviewTeam.reviewYear, 06, 12), "days") > 0;//supposed to be until july meeting
                            }
                        }

                        scope.isWithinReviewYear = scope.daysFromVisitEnded < 366;

                        scope.data = dueResponseSvc.data;
                        scope.isDataReady = true;
                    });
                }();
            }
        };
    });

}(angular.module('statement')));