(function (module) {

    var replacePevCtrl = function (pev, program, add, isObserver, $scope, $stateParams, $uibModalInstance, $filter, assignmentSvc, alertSvc, teamMemberTypeNames, pevAssignmentSvc) {
        var model = $scope;

        model.isAddMode = add ? true : false;
        model.isObserver = isObserver ? true : false;
        model.isReplaceMode = !model.isAddMode;
        model.selectedPev = null;
        model.isSearching = false;
        model.noResults = false;

        if (model.isAddMode) {

            model.title = model.isObserver ? 'Add observer' :'Add PEV';
            model.btnText = 'Save';
            model.message = model.isObserver ? "To add an observer search for the observer you want to assign. Only observer that do not have conflicts with the program will appear as results." : "To add a PEV search for the PEV you want to assign. Only PEVs that do not have conflicts with the program will appear as results.";

        } else if (model.isReplaceMode) {

            model.title = model.isObserver ? 'Replace observer' : 'Replace PEV';
            model.btnText = 'Replace';
            model.formerPev = angular.copy(pev);
            model.message = model.isObserver ? "To replace an assigned observer search for the new observer you want to assign. Only observers that do not have conflicts with the program will appear as results." : "To replace an assigned PEV search for the new PEV you want to assign. Only PEVs that do not have conflicts with the program will appear as results.";
        }

        model.close = function () {
            $uibModalInstance.close();
        };

        model.save = function () {
            if (model.selectedPev && model.selectedPev.volunteerId) {

                if (model.isAddMode) {

                    if (model.isObserver) {
                        assignmentSvc.assign(model.selectedPev, assignmentSvc.assignmentTypes.OBSERVER, program, model.selectedVolunteers);

                    } else {
                        assignmentSvc.assign(model.selectedPev, assignmentSvc.assignmentTypes.PEV, program, model.selectedVolunteers);
                    }
                    model.close();

                } else if (model.isReplaceMode) {
                    var assignment;

                    if (model.isObserver) {
                        assignment = program.observersAssigned.find(function (obs) {
                            return obs.volunteerId === model.formerPev.volunteerId;
                        });

                         if (assignment) {
                             assignmentSvc.delete(assignment).then(function () {
                                 assignmentSvc.assign(model.selectedPev, assignmentSvc.assignmentTypes.OBSERVER, program, model.selectedVolunteers);
                                 model.close();
                             });
                         }

                    } else {
                        assignment = program.pevsAssigned.find(function (pev) {
                            return pev.volunteerId === model.formerPev.volunteerId;
                        });

                        if (assignment) {
                            assignmentSvc.delete(assignment).then(function () {
                                assignmentSvc.assign(model.selectedPev, assignmentSvc.assignmentTypes.PEV, program, model.selectedVolunteers);
                                model.close();
                            });
                        }
                    }
                    
                }
            }
        };

        model.onSelectVolunteer = function ($item) {
            model.selectedPev = $item;
        };

        model.searchVolunteers = function (keyword) {
            model.noResults = false;
            if (keyword.length < 3) {
                model.isSearching = false;
                model.noResults = false;
                return;
            }
            model.isSearching = true;
            var programReviewDisciplineId = program.programReviewDisciplineId;
            var societyId = parseInt($stateParams.societyId);


            if (model.isObserver) {
                return pevAssignmentSvc.searchObserversWithoutConflicts(keyword, programReviewDisciplineId, societyId).then(function (data) {
                    if (data.data.value.length == 0) {
                        model.noResults = true;
                        model.isSearching = false;
                    }
                    else {
                        model.noResults = false;
                        model.isSearching = false;
                    }
                    return $filter('unique')(data.data.value, 'volunteerId');
                });
            } else {
                return pevAssignmentSvc.searchWithoutConflicts(keyword, programReviewDisciplineId, societyId).then(function (data) {
                    if (data.data.value.length == 0) {
                        model.noResults = true;
                        model.isSearching = false;
                    }
                    else {
                        model.noResults = false;
                        model.isSearching = false;
                    }
                    
                    return $filter('unique')(data.data.value, 'volunteerId');
                });
            }
        };
    };

    module.controller('replacePevCtrl', replacePevCtrl);

}(angular.module('assignment')));