(function (module) {

    var templateRoot = "/Apps/assignment/templates/tc/";

    module.directive('teamGroupings', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'teamGroupings.html'

        };
    });

    module.directive('reviewTeamFilters', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'reviewTeamFilters.html'
        };
    });

    module.directive('tcCommissionerFilters', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'tcCommissionerFilters.html'
        };
    });

    module.directive('editorCommissionerFilters', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'editorCommissionerFilters.html'
        };
    });

    module.directive('assignmentStatus', function () {
        return {
            restrict: 'E',
            template: '<label>Assignment Status</label>' +
                      '<select class="form-control input-sm" ng-model="filters[filterName]">' +
                          '<option value="all">All</option>' +
                          '<option value="assigned">Assigned</option>' +
                          '<option value="unassigned">Unassigned</option>' +
                      '</select>',
            scope: {
                filters: '=',
                filterName: '@'
            }
        };
    });

    module.directive('reviewTeamSummary', function (tcAssignmentSvc, assignmentSvc, alertSvc, teamMemberTypeNames) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'reviewTeamSummary.html',
            scope: {
                reviewTeams: '=',
                limit: '@?',
                hasDelete: '@?',
                selectionMode: '@?',
                selectedReviewTeams: '=?',
                selectedVolunteers: '=?'
        },
            link: function (scope, elem, attrs) {
                scope.getReviewTypeHtml = tcAssignmentSvc.getReviewTypeHtml;

                scope.hasStatus = {
                    pending: function (volunteer) { return getStatus(volunteer, 1, 4); },
                    approved: function (volunteer) { return getStatus(volunteer, 5, 5); },
                    declined: function (volunteer) { return getStatus(volunteer, 6, 8); }
                };

                function getStatus(volunteer, rangeBegin, rangeEnd) {
                    var id = volunteer.teamMemberStatusId;
                    return id ? (id >= rangeBegin && id <= rangeEnd) : false;
                }

                scope.teamMemberTypeNames = teamMemberTypeNames;

                scope.allSelected = { checked: false };
                scope.limit = scope.limit || null;

                var defaultSort = 'sortName';
                scope.setSortFilter = function () { scope.sortFilter = defaultSort; };

                scope.sortBy = function (property) {
                    if (typeof property === 'string') {
                        scope.sortFilter = assignmentSvc.setSortBy(defaultSort, scope.sortFilter, property);
                    }
                };

                scope.teamChairsReleased = function () { return tcAssignmentSvc.data.teamChairsReleased; }
                scope.editor1Released = function () { return tcAssignmentSvc.data.editor1Released; }
                scope.editor2Released = function () { return tcAssignmentSvc.data.editor2Released; }

                scope.hasDelete = scope.hasDelete && attrs.hasDelete.toLowerCase() === 'true' ? true : false;

                if (attrs.selectionMode && attrs.selectionMode.toLowerCase() === 'true') {
                    scope.selectionMode = true;
                    scope.previewMode = false;
                } else {
                    scope.selectionMode = false;
                    scope.previewMode = true;
                }

                scope.selectAll = function (selectAll) {
                    if (!scope.reviewTeams) return;
                    var tempCounter = 0;

                    scope.allSelected.checked = (selectAll === undefined) ? scope.allSelected.checked : selectAll;
                    
                    scope.reviewTeams.forEach(function (item) {
                        if (scope.allSelected.checked) ++tempCounter;
                        item.selected = scope.allSelected.checked;
                    });

                    tcAssignmentSvc.reviewSelectionCounter = tempCounter;
                };

                scope.incrementCounter = function (reviewTeam) {
                    if (reviewTeam.selected) {
                        ++tcAssignmentSvc.reviewSelectionCounter;
                    } else {
                        --tcAssignmentSvc.reviewSelectionCounter;
                    }
                };

                scope.delete = function (commissioner, teamMemberTypeId, reviewTeam) {
                    if (scope.selectedReviewTeams && scope.selectedVolunteers && tcAssignmentSvc.data.matrix) {
                        // Summary is called from contect of assignment tool; need to call unassign instead of simply deleting.
                        // Need to look up actual volunteer object that has assignments to pass into unassign (commissioner doesn't cut it)
                        var selectedReviewTeam = scope.selectedReviewTeams.find(function (item) { return item.reviewTeamId === reviewTeam.reviewTeamId });
                        var selectedMatrixRow = tcAssignmentSvc.data.matrix.find(function (item) { return item.reviewTeamId === selectedReviewTeam.reviewTeamId; });
                        var selectedVolunteer = selectedMatrixRow.volunteers ? 
                                                    selectedMatrixRow.volunteers.find(function (item) { return item.volunteerId === commissioner.volunteerId; }) :
                                                    null;

                        if (selectedVolunteer) {
                            assignmentSvc.unassign(selectedVolunteer, teamMemberTypeId, selectedReviewTeam, scope.selectedVolunteers);
                            return;
                        }
                    }
                    // Volunteer not in matrix, delete as usual.
                    var tcMode;
                    alertSvc.confirmDelete(commissioner.lastName + ', ' + commissioner.firstName, assignmentSvc.delete, commissioner, tcMode = true);
                };

                scope.getProgramsSummary = tcAssignmentSvc.getProgramsSummary;
                scope.getCommissionerName = assignmentSvc.getCommissionerName;

                scope.$watch('reviewTeams', function () {
                    assignmentSvc.clearIndex(scope.reviewTeams);
                    scope.selectAll(false);
                    scope.setSortFilter();
                });
            }

        };
    });

    module.directive('commissionerSummary', function (tcAssignmentSvc, assignmentSvc, helperSvc, volunteerHelperSvc, teamMemberTypes, teamMemberTypeNames, onsiteAvailabilityTypes) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'commissionerSummary.html',
            scope: {
                commissioners: '=',
                limit: '@?',
                tcMode: '@?',
                editor1Mode: '=?'
            },
            link: function (scope, elem, attrs) {
                scope.disabledSelectionMsg = "Volunteer must agree to the ABET Code of Conduct and Covid-19 Policy. Once the volunteer has confirmed, they will be available for selection.";

                scope.allSelected = { checked: false };
                scope.commissioners = scope.commissioners || [];
                scope.limit = scope.limit || null;
                scope.tcMode = scope.tcMode && attrs.tcMode.toLowerCase() === "true" ? true : false;
                scope.editor1Mode = scope.editor1Mode;
                scope.getCommissionerName = assignmentSvc.getCommissionerName;
                scope.getDateConflictText = assignmentSvc.getDateConflictText;
                scope.getDateConflictTypeName = assignmentSvc.getDateConflictTypeName;
                scope.getConflictDates = assignmentSvc.getConflictDates;
                scope.formatDate = helperSvc.formatDate;
                scope.getCountryConflictText = assignmentSvc.getCountryConflictText;
                scope.getConflictCountries = assignmentSvc.getConflictCountries;
                scope.getCountryConflictTypeName = assignmentSvc.getCountryConflictTypeName;
                scope.noAvailableCountriesMsg = "Commissioner is not available for any visits.";
                scope.getOnsiteVirtualAvailability = function (travel, virtual) {
                    var travelStr = onsiteAvailabilityTypes.find(t => t.id === travel).type;
                    var virtualStr = onsiteAvailabilityTypes.find(t => t.id === virtual).type;
                    return 'Travel: ' + (travelStr ?? 'N/A') + '   ' + 'Virtual: ' + (virtualStr ?? 'N/A');
                };

                var defaultSort = 'lastName';
                scope.setSortFilter = function () { scope.sortFilter = defaultSort; };

                scope.sortBy = function (property) {
                    if (typeof property === 'string') {
                        scope.sortFilter = assignmentSvc.setSortBy(defaultSort, scope.sortFilter, property);
                    }
                };

                scope.showCheckbox = function (commissioner, isDisabled) {
                    var meetsReqs = commissioner.isConfirmedTermsAgreement && commissioner.isConfirmedHealthSafety;
                    return isDisabled ? !meetsReqs : meetsReqs;
                };

                scope.getAssignedReviewsByAssignment = function (assignedReviews) {
                    //return only visits that are TC or COTC
                    if (assignedReviews && assignedReviews.length > 0) {
                        if (scope.tcMode) {
                            return helperSvc.getFilteredArray(assignedReviews, 'teamMemberTypeId', teamMemberTypes.TEAMCHAIRS, true);
                        } else if (scope.editor1Mode) {//return only visits that are ED1 or ED2
                            return helperSvc.getFilteredArray(assignedReviews, 'teamMemberTypeId', teamMemberTypeNames.EDITOR1, true);
                        }
                        else {
                            return helperSvc.getFilteredArray(assignedReviews, 'teamMemberTypeId', teamMemberTypeNames.EDITOR2, true);
                        }
                    }
                    return [];
                }

                scope.openBioModal = function(volunteer){
                    var useFullBio = false;

                    volunteerHelperSvc.openBioModal(volunteer, useFullBio);
                }

                scope.selectAll = function (selectAll) {
                    if (!scope.commissioners) return;
                    var tempCounter = 0;

                    scope.allSelected.checked = (selectAll === undefined) ? scope.allSelected.checked : selectAll;

                    scope.commissioners.forEach(function (item) {

                        if (scope.showCheckbox(item)) {
                            if (scope.allSelected.checked)++tempCounter;
                            item.selected = scope.allSelected.checked;
                        }
                        //if (scope.allSelected.checked) ++tempCounter;
                        //item.selected = scope.allSelected.checked;
                    });

                    tcAssignmentSvc.commissionerSelectionCounter = tempCounter;
                };

                scope.incrementCounter = function (commissioner) {
                    if (commissioner.selected) {
                        ++tcAssignmentSvc.commissionerSelectionCounter;
                    } else {
                        --tcAssignmentSvc.commissionerSelectionCounter;
                    }
                };

                scope.$watch('commissioners', function () {
                    assignmentSvc.clearIndex(scope.commissioners);
                    scope.selectAll(false);
                    scope.setSortFilter();
                });
            }
        };
    });

    module.directive('organizationUserSearch', function (membershipSvc, helperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'organizationUserSearch.html',
            require: '^?form',
            scope: {              
                noResults: '=?',
                noResultsMsg: '@?',
                noResultsFunc: '&?',
                selectedUser: '=?',
                commissionId: '=?',
                disableOnSelection: '@?'
            },
            link: function (scope) {
                scope.container = {};
                scope.noResults = false;
                scope.disable = scope.disableOnSelection ? (scope.disableOnSelection.toLowerCase() == "true" ? true : false) : false;

                scope.onSelectUser = function (item, model, label) {
                    scope.selectedUser = item;
                };

                scope.searchPerson = function (val) {
                    
                    if (val.length < 3) {
                        return;
                    }

                    return membershipSvc.quickSearchOrganizationUsers(scope.commissionId, val).then(function (data) {
                        var results = data.value;

                        scope.noResults = results.length > 0 ? false : true;
                        uniqueResults = helperSvc.uniqueArrayByKey(results, 'personId');

                        return uniqueResults;
                    });
                };
            }
        };
    });

}(angular.module('assignment')));