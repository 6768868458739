(function (module) {
    'use strict';
    
    var evaluatorFollowUpCtrl = function ($scope, $uibModal, organizationTypes, commissionTypes, appraisalTypes, organizationSvc, appraisalReportSvc, codeSvc,oauth,currentUser, volunteerSvc) {
            var model = this;

            model.title = 'Evaluator Follow-Up';

            // search properties
            model.showSpinnerSearch = false;
            model.societyId = undefined;
            model.commissionIds = [];
            model.pevTc = undefined;
            model.personSearch = '';
            model.societies = [];
            model.commissions = [];
            model.satisfactions = [];
            model.years = [];
            model.yearOptions = getYearOptions();
            model.multiselectCharMax = 100;

            // results properties
            model.searchResults = [];
            model.resultMsg = 'Waiting on search...'; // Set this once it's useful
            model.showSpinner = false;
            model.appraisalTypes = [];
            model.recommendAsTCs = [];
            model.confidential = '';
            model.submittedFrom = '';
            model.comments = '';
            model.submittedTo = '';

            model.currentUser = oauth.getUserInfo();
            model.satisfactionTypes = [
                { id: 'Yes, without reservation' },
                { id: 'Yes, but with some reservation' },
                { id: 'No'}
            ]   
            model.recommendAsTCTypes = [
                { id: 'Yes' },
                { id: 'Yes, but with additional experience' },
                { id: 'No'}
            ]
            model.appraisalTypeOptions = [
                {
                    id: appraisalTypes.TEAMCHAIR_APPRAISAL_OF_PEV,
                    name: 'TC evaluation of PEV'
                },
                {
                    id: appraisalTypes.PEV_APPRAISAL_OF_TEAMCHAIR,
                    name: 'PEV evaluation of TC'
                },
                {
                    id: appraisalTypes.PEV_APPRAISAL_OF_PEV,
                    name: 'PEV evaluation of PEV'
                },
                {
                    id: appraisalTypes.EDITOR_APPRAISAL_OF_TEAMCHAIR,
                    name: 'Editor evaluation of TC'
                },
                {
                    id: appraisalTypes.DEANS_APPRAISAL_OF_TEAMCHAIR,
                    name: "Dean's evaluation of TC"
                },
                {
                    id: appraisalTypes.DEPARTMENTHEAD_APPRAISAL_OF_PEV,
                    name: 'Dept evaluation of PEV'
                },
                {
                    id: appraisalTypes.TC_APPRAISAL_OF_TC,
                    name: 'TC evaluation of TC'
                }
            ];


            // public method declarations
            model.reset = reset;
            model.retrieve = retrieve;
            model.generateSimpleReport = generateSimpleReport;
            model.generateDetailedReport = generateDetailedReport;
            model.generatePrevFiveReport = generatePrevFiveReport;
            model.getSatisfactionGradeIcon = appraisalReportSvc.getSatisfactionGradeIcon;
            model.getRecommendAsTCIcon = appraisalReportSvc.getRecommendAsTCIcon;

            model.isExcom = currentUser.profile.userTasks.some(function (task) { return task == 'excom'; });
            model.isSociety = currentUser.profile.userTasks.some(function (task) { return task == 'manage volunteer' && 'manage training'; }); //society user
            model.isAdmin = oauth.isAdmin();
            model.isBridgeCordinator = currentUser.profile.userRoles.some(function (r) { return r.roleId == 71; });

            model.openCurrentYearSearchModal = function (appraiseePersonId) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/evaluatorFollowUp/templates/modal/searchModal.html',
                    size: 'xl',
                    controller: 'currentYearSearchModalCtrl',
                    resolve: {
                        appraiseePersonId: function () {
                            return appraiseePersonId;
                        },
                        searchParams: function () {
                            return createSearchParams();
                        }
                    }
                });
            };

            model.openPastFiveSearchModal = function (appraiseePersonId) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: '/Apps/evaluatorFollowUp/templates/modal/searchModal.html',
                    size: 'xl',
                    controller: 'pastFiveYearSearchModalCtrl',
                    resolve: {
                        appraiseePersonId: function () {
                            return appraiseePersonId;
                        }
                    }
                });
            };

            activate();

            /**
             * Loads supporting data for this controller.
             */
            function activate() {

                model.showSpinnerSearch = true;

                codeSvc.getMemberSocieties().then(function (data) {
                    if (model.isSociety && !model.isAdmin)
                        model.societies = getOrganizationOptionsByCurrentUser(data.value, [200, 203, 204, 207, 220]);
                    else
                        model.societies = data.value;

                    codeSvc.getCommissions().then(function (com) {
                        if (model.isExcom && !model.isAdmin)
                            model.commissions = getOrganizationOptionsByCurrentUser(com.value, [300, 301, 302, 306, 307, 308]);
                        else
                            model.commissions = com.value;
                    });

                    model.showSpinnerSearch = false;
                });
            }

            function getOrganizationOptionsByCurrentUser(options, filterArray) {
                var roles = currentUser.profile.userRoles;
                return options.filter(opt =>
                    roles.filter(role => role.organizationId == opt.codeKey && filterArray.includes(role.roleId))
                        .map(t => t.organizationId).includes(opt.codeKey))
            };

            function getYearOptions() {
                var startYear = 2018;
                var currentYear = new Date().getFullYear();
                var numberOfYearsToAdd = 2 + currentYear - startYear;   //+1 because we need to loop atleast once for the year 2016
                var tyears = [];

                for (var i = 0; i < numberOfYearsToAdd; i++) {
                    tyears.push({ year: startYear + i, display: `Review Year ${startYear + i}`});
                };

                return tyears;
            }

            function reset() {
                model.societyId = undefined;
                model.commissionIds = [];
                model.years = [];
                model.pevTc = undefined;
                model.personSearch = undefined;
                model.appraisalTypes = [];
                model.satisfactions = [];
                model.recommendAsTCs = [];
                model.confidential = undefined;
                model.comments = undefined;
                model.submittedFrom = undefined;
                model.submittedTo = undefined;
            }

            function retrieve() {
                const searchParams = createSearchParams(false);
                retrieveSearchData(searchParams);
            }

            function generateSimpleReport() {
                const searchParams = createSearchParams(false);
                appraisalReportSvc.exportSimpleReport(searchParams, model.isAdmin && !model.isBridgeCordinator);
            }

            function generateDetailedReport(isAllAppraisals) {
                const searchParams = createSearchParams(true);
                appraisalReportSvc.exportDetailedReport(searchParams, model.isAdmin && !model.isBridgeCordinator, isAllAppraisals);
            }

            function generatePrevFiveReport() {
                const searchParams = createSearchParams(true);
                searchParams.isPreviousRecentReview = true;
                appraisalReportSvc.exportDetailedReport(searchParams, model.isAdmin && !model.isBridgeCordinator);
            }

            function personCurrentYearSearch(appraiseePersonId) {
                const searchParams = createSearchParams(true);
                const currentYear = moment().year();
                searchParams.years = [currentYear];
                searchParams.appraiseePersonId = appraiseePersonId;

                retrieveSearchData(searchParams);
            }

            function personPastFiveSearch(appraiseePersonId) {
                const searchParams = createSearchParams(true);
                searchParams.isPreviousRecentReview = true;
                searchParams.appraiseePersonId = appraiseePersonId;

                retrieveSearchData(searchParams);
            }

            function createSearchParams(includeOtherEval) {
                return {
                    societyId: model.societyId,
                    commissionIds: model.commissionIds.map(t => t.codeKey),
                    years: model.years.map(y => y.year),
                    pevTc: model.pevTc,
                    personSearch: model.personSearch,
                    appraisalTypes: model.appraisalTypes.map(t => t.id),
                    satisfactions: model.satisfactions,
                    recommendAsTCs: model.recommendAsTCs.length === 3 ? [] : model.recommendAsTCs,
                    confidential: model.confidential,
                    comments: model.comments,
                    submittedFrom: model.submittedFrom,
                    submittedTo: model.submittedTo,
                    userPersonId: model.currentUser.personId,

                    //when detailed report exports, the list includes all other evaluations even though a certain comment, satisfaction, confidential, recommendAsTC option is selected
                    includeOthers: includeOtherEval  
                };
            }

            function retrieveSearchData(searchParams) {
                model.resultMsg = '';
                model.showSpinner = true;
                appraisalReportSvc.getReports(searchParams)
                    .then(data => {
                        model.searchResults = data;
                        model.showSpinner = false;
                        if (model.searchResults.length === 0)
                            model.resultMsg = 'No records found';
                    })
                    .catch(err => {
                        console.error(err);
                        model.resultMsg = 'Error retrieving data';
                        model.showSpinner = false;
                    });
            }
        }

    module.controller('evaluatorFollowUpCtrl', evaluatorFollowUpCtrl);

}(angular.module('evaluatorFollowUp')));