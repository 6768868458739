(function (module) {

    var pevAssignmentSvc = function ($http, $odataresource, $q, $uibModal, odataSvc, helperSvc, alertSvc, programAreaSvc, codeSvc, messageSvc, teamMemberTypeNames, membershipSvc, messageTemplateTypes, eventSvc) {
        var factory = {};
        var currentSocietyId = null;
        var societyIdForCache = null;
        var currentMatrixParams = {
            programReviewDisciplineIds: null,
            volunteerIds: null,
            societyId: null
        };

        factory.data = {
            reviewPrograms: null,
            pevs: null,
            programAreaOptions: null,
            programReviewTypeOptions: null,
            matrix: null
        };

        var blankFilters = {
            reviewProgramFilters: {
                assignment: '',
                programReviewType: '',
                rangeStartDate: null,
                rangeEndDate: null,
                programAreas: [],
                distanceLearning: null,
                usOnly: null,
                nonUsOnly: null,
                societyObserver: null,
                excludeUnconfirmedStartDate: null,
                onsiteVisitRestriction: []
            },
            volunteerFilters: {
                assignment: '',
                programAreas: [],
                rangeStartDate: null,
                rangeEndDate: null,
                experienceRange: null,
                hasHadNonUsVisit: null,
                excludeCommissioners: null,
                excludeHadVisitLastYear: null,
                excludeHadVisitTwoYearsAgo: null,
                excludeUnconfirmedTerms: null,
                volunteerTravelAvailability: null,
                volunteerVirtualAvailability: null
            }
        }

        factory.filters = {};

        factory.resetFilters = function () {
            angular.copy(blankFilters, factory.filters);
        }

        factory.resetFilters();

        factory.pevReqs = {
            hasAssignees: function (p) { return p.pevsRequired ? (p.pevsAssigned ? p.pevsAssigned.length >= p.pevsRequired : false) : false; },
            requiresPevs: function (p) { return p.pevsRequired !== 0; },
            hasStartDate: function (p) { return p.visitStartDate !== 'N/A'; },
            pevsReleased: function (p) { return factory.pevReqs.hasAssignees(p) ? p.pevsAssigned.every(function (pev) { return pev.statusName && pev.statusName != "Not Released" }) : false; },
            observersReleased: function (p) { return p.observersAssigned ? p.observersAssigned.every(function (obs) { return obs.statusName && obs.statusName != "Not Released" }) : false; }
        };

        factory.releasePending = false;
        factory.showReleasableOnly = true;
        factory.releasePEVs = false;
        factory.releaseObservers = false;
        factory.programSelectionCounter = 0;
        factory.volunteerSelectionCounter = 0;

        factory.getReviewTypeHtml = function () {
            var reviewTypeHtml;

            if (factory.data.programReviewTypeOptions) {
                reviewTypeHtml = '<ul class="semi-style">' +
                                    factory.data.programReviewTypeOptions.map(function (programReviewType) {
                                        return '<li> ' + programReviewType.codeKey + ' (' + programReviewType.codeName + ')</li>';
                                    }).join('') +
                                 '</ul>';
            } else {
                reviewTypeHtml = '<ul class="semi-style">' +
                                   '<li> GR (Comprehensive Visit)</li>' +
                                   '<li> IGR (Initial Accreditation)</li>' +
                                   '<li> IV (Focused Visit)</li>' +
                                   '<li> SC (Focused Show Cause Visit)</li>' +
                                   '<li> SCV (Show Cause Visit)</li>' +
                                   '<li> TV (Termination Visit)</li>' +
                                   '<li> XV (ExCom Focused Visit)</li>' + 
                               '</ul>';
            }

            return reviewTypeHtml;
        }

        factory.getPevAssignmentReviewPrograms = function (useCache, societyId) {
            var key = 'programReviewDisciplineId';
            var oSvc = odataSvc.get();
            var path = '/GetPevAssignmentProgramReviews';
            var societyPath = path + "(societyId=" + societyId + ")";
            var promise;

            // Invalidate cache if a different societyId is being specified
            if (useCache && factory.data.reviewPrograms && societyIdForCache === societyId) {
                var deferred = $q.defer();
                promise = deferred.promise;
                deferred.resolve(factory.data.reviewPrograms);
            } else {
                var svc = oSvc.getSource(societyPath, key).odata();
                svc.query(oSvc.onSuccess, oSvc.onFailure);

                promise = oSvc.getDeferred().promise;

                promise.then(function (data) {
                    data.forEach(function (reviewProgram) {
                        // note: visitStartDate appears to be a date but is actually a varchar
                        //reviewProgram.visitStartDate = helperSvc.formatDate(reviewProgram.visitStartDate, useUndefined = true);
                        reviewProgram.simultaneousOption = angular.fromJson(reviewProgram.simultaneousOption);
                        reviewProgram.programDetail = angular.fromJson(reviewProgram.programDetail);
                        reviewProgram.pevsAssigned = angular.fromJson(reviewProgram.pevsAssigned);
                        reviewProgram.observersAssigned = angular.fromJson(reviewProgram.observersAssigned);
                        reviewProgram.assignedTcs = angular.fromJson(reviewProgram.assignedTcs);
                        reviewProgram.assignmentHistory = angular.fromJson(reviewProgram.assignmentHistory);
                    });

                    factory.data.reviewPrograms = data;
                    eventSvc.broadcast('reviewProgramsLoaded');
                    // Save the societyId so we can tell on the next call if cache is still valid.
                    societyIdForCache = societyId
                });
            }

            return promise;
        };

        factory.getVolunteers = function (useCache, societyId) {
            var key = 'personId';
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameter('/GetPevAssignmentPevs', 'societyId', societyId);
            var promise;

            if (useCache && factory.data.pevs && societyIdForCache === societyId) {
                var deferred = $q.defer();
                promise = deferred.promise;
                deferred.resolve(factory.data.pevs);
            }
            else {
                var svc = oSvc.getSource(path, key).odata();
                svc.query(oSvc.onSuccess, oSvc.onFailure);

                promise = oSvc.getDeferred().promise;

                promise.then(function (data) {
                    if (data && data.length > 0) {
                        data.forEach(function (pev) {
                            pev.visitHistory = angular.fromJson(pev.visitHistory);
                            pev.availabilityDate = angular.fromJson(pev.availabilityDate);
                            pev.availabilityCountry = angular.fromJson(pev.availabilityCountry);
                            pev.assignedReviews = angular.fromJson(pev.assignedReviews);
                            pev.numberOfVisits = angular.isArray(pev.visitHistory) ? pev.visitHistory.length : 0;
                            pev.volunteerCountries = angular.fromJson(pev.volunteerCountries) || [];
                        });
                        factory.data.pevs = data;
                        // Save the societyId so we can tell on the next call if cache is still valid.
                        societyIdForCache = societyId
                    }
                });
            }

            return promise;
        };

        factory.getMatrixRecords = function (programReviewDisciplineIds, volunteerIds, societyId) {
            var data = {
                programReviewDisciplineIds: programReviewDisciplineIds,
                volunteerIds: volunteerIds,
                societyId: societyId
            };

            currentMatrixParams = data;

            var oSvc = odataSvc.get();
            var path = '/webapi/odata/GetPevMatrixRecords';

            return $http.post(path, data).then(function (response) {
                return response;
            });
        };

        factory.isPEV = function (commissioner) {
            return membershipSvc.isRole(commissioner, teamMemberTypeNames.PEV);
        };

        factory.isObserver = function (commissioner) {
            return membershipSvc.isRole(commissioner, teamMemberTypeNames.PROGRAMOBSERVER);
        };

        factory.refreshReviewPrograms = function () {
            return factory.getPevAssignmentReviewPrograms(false, currentSocietyId);
        }

        factory.refreshVolunteers = function () {
            return factory.getVolunteers(false, currentSocietyId);
        }

        factory.refreshMatrix = function () {
            if (currentMatrixParams.programReviewDisciplineIds) {
                return factory.getMatrixRecords(currentMatrixParams.programReviewDisciplineIds, currentMatrixParams.volunteerIds, currentMatrixParams.societyId).then(function (data) {
                    factory.data.matrix = data.data.value;
                });
            }
        };

        factory.refreshData = function () {
            var programPromise = factory.refreshReviewPrograms();
            var volunteerPromise = factory.refreshVolunteers();

            return $q.all([programPromise, volunteerPromise]); 
        };

        var reviewProgramsDataSource = {
            storeData: false,
            svcCallback: factory.getPevAssignmentReviewPrograms,
            svcCallbackArguments: [useCache = true, currentSocietyId]
        }

        var pevDataSource = {
            storeData: false,
            svcCallback: factory.getVolunteers,
            svcCallbackArguments: [useCache = true, currentSocietyId]
        }

        var programAreaOptionsDataSource = {
            dataHolder: factory,
            dataLocationName: 'data.programAreaOptions',
            svcCallback: programAreaSvc.getDisciplineSociety,
            svcCallbackArguments: [null, currentSocietyId],
            odataResource: true,
            optionalCallback: function () {
                var programAreaOptions = angular.copy(factory.data.programAreaOptions);
                for (var index = programAreaOptions.length - 1; index >= 0; index--) {
                    if (programAreaOptions[index].disciplineName.toLowerCase().indexOf('general criteria') > -1) {
                        programAreaOptions.splice(index, 1);
                    }
                }
                factory.data.pevProgramAreaOptions = programAreaOptions;
            }
        }

        var onsiteVisitRestrictionOptionsDataSource = {
            dataHolder: factory,
            dataLocationName: 'data.onsiteVisitRestrictionOptions',
            svcCallback: codeSvc.getOnsiteVisitRestrictionStatuses,
            helperCallback: helperSvc.getResults
        };

        // Get program review types for visits only; can't assign PEVs to reports
        var methodTypeCode = 'V'; 
        var programReviewTypeOptionsDataSource = {
            dataHolder: factory,
            dataLocationName: 'data.programReviewTypeOptions',
            svcCallback: codeSvc.getProgramReviewTypes,
            svcCallbackArguments: [methodTypeCode],
            helperCallback: helperSvc.getResults
        };

        var loadAllDataPromise = null;

        factory.loadAllData = function (societyId) {
            if (loadAllDataPromise && currentSocietyId === societyId) return loadAllDataPromise;

            currentSocietyId = societyId;
            reviewProgramsDataSource.svcCallbackArguments[1] = currentSocietyId;
            pevDataSource.svcCallbackArguments[1] = currentSocietyId;
            programAreaOptionsDataSource.svcCallbackArguments[1] = currentSocietyId;

            var dataSourceArray = [
				reviewProgramsDataSource,
                pevDataSource,
                programAreaOptionsDataSource,
                programReviewTypeOptionsDataSource,
                onsiteVisitRestrictionOptionsDataSource
            ];

            loadAllDataPromise = helperSvc.getData(dataSourceArray).then(function () {
                loadAllDataPromise = null;
            });

            return loadAllDataPromise;
        };

        factory.getPEVsRatio = function (program) {
            var assigned = program.pevsAssigned ? program.pevsAssigned.length : 0;
            var required = program.pevsRequired ? program.pevsRequired : 0;

            if (required === 0 && assigned === 0) {
                return "N/A";
            } else {
                return assigned + "/" + required;
            }
        };

        factory.getLocation = function (program) {
            var state = program.stateCode ? program.stateCode : "";
            var country = program.countryName;
            if (country && country.toLowerCase().indexOf("united states") > 0) {
                country = "US";
            }

            return state + (state !== "" ? ", " : "") + country;
        };

        factory.releasePevs = function (programsSelected) {
            return releaseTeamMembers(programsSelected, teamMemberTypeNames.PEV);
        };

        factory.releaseObservers = function (programsSelected) {
           return  releaseTeamMembers(programsSelected, teamMemberTypeNames.PROGRAMOBSERVER);
        }

        factory.searchWithoutConflicts = function (keyword, programReviewDisciplineId, societyId) {
            var data = {
                keyword: keyword,
                programReviewDisciplineId: programReviewDisciplineId,
                societyId: societyId
            };
            var oSvc = odataSvc.get();
            var path = '/webapi/odata/SearchForPevReplacement';

            return $http.post(path, data).then(function (response) {
                return response;
            });
        }

        factory.searchObserversWithoutConflicts = function (keyword, programReviewDisciplineId, societyId) {
            var data = {
                keyword: keyword,
                programReviewDisciplineId: programReviewDisciplineId,
                societyId: societyId
            };
            var oSvc = odataSvc.get();
            var path = '/webapi/odata/SearchForObserverReplacement';

            return $http.post(path, data).then(function (response) {
                return response;
            });
        }

        factory.searchFromOtherSocieties = function (programReviewId, societyIds, disciplineIds ) {

            var data = {
                programReviewId: programReviewId,
                societyIds: societyIds,
                disciplineIds: disciplineIds
            };
            var oSvc = odataSvc.get();
            var path = '/webapi/odata/SearchForPevFromOtherSociety';

            return $http.post(path, data).then(function (response) {
                return response;
            });

        }

        function releaseTeamMembers(programsSelected, type) {

            var data = {
                programReviewDisciplineIds: programsSelected && programsSelected.length > 0 ? programsSelected.map(function (prog) { return prog.programReviewDisciplineId }).toString() : '',
                reviewYear: factory.data.reviewPrograms[0].reviewYear,
                societyId: currentSocietyId,
                teamMemberTypeId: type
            };

            var path = '/webapi/odata/ReleasePevAssignment';

            var promise = $http.post(path, data).then(function (response) {
                factory.refreshData();
            });

            return promise;
        }

        // PDF Modal Funcs
        var pdfPath = '/Content/files/PEV-Assignment-Instructions-04012022.pdf';
        var pageNumbers = {
            FILTERS: 7,
            MATRIX: 10
        };

        factory.openMasterInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'PEV Assignment Instructions');
        };

        factory.openFilterInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Filter Instructions', pageNumbers.FILTERS);
        };

        factory.openMatrixInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Filter Instructions', pageNumbers.MATRIX);
        };

        return {
            data: factory.data,
            filters: factory.filters,
            resetFilters: factory.resetFilters,
            pevReqs: factory.pevReqs,
            getReviewTypeHtml: factory.getReviewTypeHtml,
            getPevAssignmentReviewPrograms: factory.getPevAssignmentReviewPrograms,
            getVolunteers: factory.getVolunteers,
            getMatrixRecords: factory.getMatrixRecords,
            isPEV: factory.isPEV,
            isObserver: factory.isObserver,
            refreshReviewPrograms: factory.refreshReviewPrograms,
            refreshData: factory.refreshData,
            loadAllData: factory.loadAllData,
            getPEVsRatio: factory.getPEVsRatio,
            getLocation: factory.getLocation,
            releasePending: factory.releasePending,
            showReleasableOnly: factory.showReleasableOnly,
            releasingPEVs: factory.releasingPEVs,
            releasingObservers: factory.releasingObservers,
            programSelectionCounter: factory.programSelectionCounter,
            volunteerSelectionCounter: factory.volunteerSelectionCounter,
            releasePevs: factory.releasePevs,
            releaseObservers: factory.releaseObservers,
            searchWithoutConflicts: factory.searchWithoutConflicts,
            searchObserversWithoutConflicts: factory.searchObserversWithoutConflicts,
            openMasterInstructions: factory.openMasterInstructions,
            openFilterInstructions: factory.openFilterInstructions,
            openMatrixInstructions: factory.openMatrixInstructions,
            searchFromOtherSocieties: factory.searchFromOtherSocieties
        };

    }

    module.factory('pevAssignmentSvc', pevAssignmentSvc);

})(angular.module('assignment'));
