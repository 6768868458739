(function (module) {

    var tcAssignmentSvc = function ($http, $odataresource, $q, $uibModal, odataSvc, helperSvc, messageTemplateTypes, contactRoleTypes,
        messageSvc, alertSvc, availabilityTypes, codeSvc, currentUserHelper, typeConstSvc, teamMemberTypeNames, membershipSvc, reviewTypes) {
        var key = '';
        var factory = {};
        var programReviewTypeCodes = typeConstSvc.getProgramReviewTypeCodes();
        var commissionIdforReviewTeams = null;
        var commissionIdforCommissioners = null;
        var currentMatrixParams = {
                reviewTeamIds: null,
                volunteerIds: null,
                commissionId: null,
                teamMemberTypeId: null
            };


        factory.data = {
            reviewTeams: null,
            commissioners: null,
            societyOptions: null,
            matrix: null,
            teamChairsReleased: false,
            editor1Released: false,
            editor2Released: false
        };

        factory.reviewSelectionCounter = 0;
        factory.commissionerSelectionCounter = 0;

        factory.getReviewTypeHtml = function () {
            var t = reviewTypes;
            return '<ul class="semi-style">' +
                        '<li> GR (' + t.GR + ')</li>' +
                        '<li> INIT (' + t.INIT + ')</li>' +
                        '<li> IR (' + t.IR + ')</li>' +
                        '<li> IV (' + t.IV + ')</li>' +
                    '</ul>';
        }

        factory.getTCAssignmentReviewTeams = function (useCache, commissionId) {
            var key = 'reviewTeamId';
            var oSvc = odataSvc.get();
            var path = '/GetTCAssignmentReviewTeams';
            // Only return review teams under the specified commission unless omitted.
            // Admin users without vice-ops role must impersonate a user who does.
            var commissionPath = path + "(commissionId=" + commissionId + ")";
            var promise;

            // Invalidate cache if a different commissionId is being specified
            if (useCache && factory.data.reviewTeams && commissionIdforReviewTeams === commissionId) {
                var deferred = $q.defer();
                promise = deferred.promise;
                deferred.resolve(factory.data.reviewTeams);
            } else {
                var svc = oSvc.getSource(commissionPath, key).odata();
                svc.query(oSvc.onSuccess, oSvc.onFailure);

                promise = oSvc.getDeferred().promise;

                promise.then(function (data) {
                    data.forEach(function (reviewTeam) {
                        reviewTeam.suggestedVisitDate = helperSvc.formatDate(reviewTeam.suggestedVisitDate, useUndefined = true, true);
                        reviewTeam.programs = angular.fromJson(reviewTeam.programs);
                        reviewTeam.jointPrograms = angular.fromJson(reviewTeam.jointPrograms);
                        reviewTeam.simultaneousVisits = angular.fromJson(reviewTeam.simultaneousVisits);
                        reviewTeam.mainCampus = angular.fromJson(reviewTeam.mainCampus);
                        reviewTeam.multipleCampuses = angular.fromJson(reviewTeam.multipleCampuses);
                        reviewTeam.teamChair = angular.fromJson(reviewTeam.teamChair);
                        reviewTeam.teamChair = reviewTeam.teamChair && reviewTeam.teamChair.length > 0 ? reviewTeam.teamChair[0] : null;
                        reviewTeam.coTeamChairs = angular.fromJson(reviewTeam.coTeamChairs);
                        reviewTeam.editor1 = angular.fromJson(reviewTeam.editor1);
                        reviewTeam.editor2 = angular.fromJson(reviewTeam.editor2);
                        reviewTeam.teamGroups = angular.fromJson(reviewTeam.teamGroups);
                    });

                    factory.data.reviewTeams = data;
                    // Save the commissionId so we can tell on the next call if cache is still valid.
                    commissionIdforReviewTeams = commissionId
                    //set the released bools
                    assignReleased();
                });
            }

            return promise;
        };

        factory.getTCAssignmentCommissioners = function (useCache, commissionId) {
            var key = 'personId';
            var oSvc = odataSvc.get();
            var path = '/GetTCAssignmentCommissioners';
            // Only return review teams under the specified commission unless omitted.
            // Admin users without vice-ops role must impersonate a user who does.
            var commissionPath = path + "(commissionId=" + commissionId + ")";
            var promise;

            if (useCache && factory.data.commissioners && commissionIdforCommissioners === commissionId) {
                var deferred = $q.defer();
                promise = deferred.promise;
                deferred.resolve(factory.data.commissioners);
            }
            else {
                var svc = oSvc.getSource(commissionPath, key).odata();
                svc.query(oSvc.onSuccess, oSvc.onFailure);

                promise = oSvc.getDeferred().promise;

                promise.then(function (data) {
                    data.forEach(function (commissioner) {
                        commissioner.visitHistory = angular.fromJson(commissioner.visitHistory);
                        commissioner.availabilityDate = angular.fromJson(commissioner.availabilityDate);
                        commissioner.availabilityCountry = angular.fromJson(commissioner.availabilityCountry);
                        commissioner.assignedReviews = angular.fromJson(commissioner.assignedReviews);
                        commissioner.volunteerCountries = angular.fromJson(commissioner.volunteerCountries) || [];
                    });

                    factory.data.commissioners = data;
                    // Save the commissionId so we can tell on the next call if cache is still valid.
                    commissionIdforCommissioners = commissionId
                });
            }

            return promise;
        };

        factory.getMatrixRecords = function (reviewTeamIds, volunteerIds, commissionId, teamMemberTypeId) {
            var data = {
                reviewTeamIds: reviewTeamIds,
                volunteerIds: volunteerIds,
                commissionId: commissionId,
                teamMemberTypeId: teamMemberTypeId
            };

            currentMatrixParams = data;

            var oSvc = odataSvc.get();
            var path = '/webapi/odata/GetTCMatrixRecords';

            return $http.post(path, data).then(function (response) {
                return response;
            });
        };

        //factory.getAssignedReviewId = function (reviewTeamId, volunteerId, teamMemberTypeId, commissionId) {
        //    var apiPath = '/ReviewAssignment';
        //    var key = 'reviewAssignmentId';

        //    var oSvc = odataSvc.get();

        //    oSvc.getSource(apiPath, key).odata()
        //        .filter('reviewTeamId', reviewTeamId)
        //        .filter('volunteerId', volunteerId)
        //        .filter('teamMemberTypeId', teamMemberTypeId)
        //        .filter('commissionId', commissionId)
        //        .select('reviewAssignmentId')
        //        .query(oSvc.onSuccess, oSvc.onFailure);

        //    return oSvc.getDeferred().promise;
        //}

        factory.isTeamChair = function (commissioner) {
            return membershipSvc.isRole(commissioner, teamMemberTypeNames.TEAMCHAIR);
        };

        factory.isCoTeamChair = function (commissioner) {
            return membershipSvc.isRole(commissioner, teamMemberTypeNames.COTEAMCHAIR);
        };

        factory.isEditor1 = function (commissioner) {
            return membershipSvc.isRole(commissioner, teamMemberTypeNames.EDITOR1);
        };

        factory.isEditor2 = function (commissioner) {
            return membershipSvc.isRole(commissioner, teamMemberTypeNames.EDITOR2);
        };

        var commissionId = currentUserHelper.getViceOpsCommissionId();

        var reviewTeamsDataSource = {
            storeData: false,
            svcCallback: factory.getTCAssignmentReviewTeams,
            svcCallbackArguments: [useCache = true, commissionId]
        };

        var commissionersDataSource = {
            storeData: false,
            svcCallback: factory.getTCAssignmentCommissioners,
            svcCallbackArguments: [useCache = true, commissionId]
        };

        var reviewTypeOptionsDataSource = {
            dataHolder: factory,
            dataLocationName: 'data.reviewTypeOptions',
            svcCallback: codeSvc.getReviewTypes,
            helperCallback: helperSvc.getResults
        };

        var societyOptionsDataSource = {
            dataHolder: factory,
            dataLocationName: 'data.societyOptions',
            svcCallback: codeSvc.getMemberSocieties,
            helperCallback: helperSvc.getResults
        };

        var onsiteVisitRestrictionOptionsDataSource = {
            dataHolder: factory,
            dataLocationName: 'data.onsiteVisitRestrictionOptions',
            svcCallback: codeSvc.getOnsiteVisitRestrictionStatuses,
            helperCallback: helperSvc.getResults
        };

        var loadAllDataPromise = null;

        factory.loadAllData = function () {
            if (loadAllDataPromise) return loadAllDataPromise;

            var dataSourceArray = [
                reviewTeamsDataSource,
                commissionersDataSource,
                reviewTypeOptionsDataSource,
                societyOptionsDataSource,
                onsiteVisitRestrictionOptionsDataSource
            ];

            loadAllDataPromise = helperSvc.getData(dataSourceArray).then(function () {
                loadAllDataPromise = null;
            });

            return loadAllDataPromise;
        };

        var blankFilters = {
            tcReviewTeamFilters: {
                assignment: '',
                reviewTypes: [],
                noOfPrograms: null,
                country: '',
                simultaneousVisit: null,
                jointVisit: null,
                mainCampus: null,
                phaseInCriteria: null,
                onsiteVisitRestriction: []
            },
            tcCommissionerFilters: {
                assignment: 'all',
                society: '',
                range: null,
                hasHadNonUsVisit: null,
                hasHadLargeTeamVisit: null,
                hasHadOnlineOnlyVisit: null,
                volunteerTravelAvailability: null,
                volunteerVirtualAvailability: null
            },
            editor1ReviewTeamFilters: {
                assignment: '',
                reviewTypes: [],
                noOfPrograms: null,
                country: '',
                simultaneousVisit: null,
                jointVisit: null,
                mainCampus: null,
                onsiteVisitRestriction: []
            },
            editor1CommissionerFilters: {
                assignment: 'all',
                society: '',
                range: null,
                hasHadNonUsVisit: null,
                hasHadLargeTeamVisit: null,
                hasHadOnlineOnlyVisit: null,
                exCom: null
            },
            editor2ReviewTeamFilters: {
                assignment: '',
                reviewTypes: [],
                noOfPrograms: null,
                country: '',
                simultaneousVisit: null,
                jointVisit: null,
                mainCampus: null,
                onsiteVisitRestriction: []
            },
            editor2CommissionerFilters: {
                assignment: 'all',
                society: '',
                range: null,
                hasHadNonUsVisit: null,
                hasHadLargeTeamVisit: null,
                hasHadOnlineOnlyVisit: null,
                exCom: null
            }
        };

        factory.filters = {};

        factory.resetFilters = function () {
            angular.copy(blankFilters, factory.filters);
        }

        factory.resetFilters();

        factory.preview = function (mode, selectedReviewTeams, selectedVolunteers) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/assignment/templates/common/assignmentSummaryEdit.html',
                size: 'xl',
                controller: 'assignmentSummaryEditCtrl',
                resolve: {
                    mode: function () { return mode; },
                    selectedReviewTeams: function () { return selectedReviewTeams; },
                    selectedVolunteers: function () { return selectedVolunteers; }
                }
            });
        };

        factory.refreshReviewTeams = function () {
            return factory.getTCAssignmentReviewTeams(false, commissionIdforReviewTeams);
        };

        factory.refreshCommissioners = function () {
            return factory.getTCAssignmentCommissioners(false, commissionIdforCommissioners);
        };

        factory.refreshMatrix = function () {
            if (currentMatrixParams.reviewTeamIds) {
                return factory.getMatrixRecords(currentMatrixParams.reviewTeamIds, currentMatrixParams.volunteerIds, currentMatrixParams.commissionId, currentMatrixParams.teamMemberTypeId).then(function (data) {
                    factory.data.matrix = data.data.value;
                });
            }
        };

        factory.refreshData = function () {
            factory.refreshReviewTeams();
            factory.refreshCommissioners();
        };

        factory.getProgramsSummary = function (reviewTeam) {
            // Return counts and summary data for various types of programs.
            // Note: it may be more efficient to incorporate this logic directly in the view or WebAPI service.
            var programsSummary = {
                newPrograms: [],
                showCause: [],
                terminated: [],
                distanceLearning: null
            };
            if (reviewTeam.programs) { 
                // Only show distance learning if more than %60 for at least one program; may make this configurable/filterable in the future.
                var minDistanceLearning = 7; // 51 - 60 %
                angular.forEach(reviewTeam.programs, function (program) {
                    switch (program.programReviewTypeCode) {
                        case programReviewTypeCodes.IGR:
                            programsSummary.newPrograms.push(program);
                            break;
                        case programReviewTypeCodes.TR:
                        case programReviewTypeCodes.TV:
                            programsSummary.terminated.push(program);
                            break;
                        case programReviewTypeCodes.SCR:
                        case programReviewTypeCodes.SCV:
                            programsSummary.showCause.push(program);
                            break;
                    }
                    // Display the highest distance learning amount >= 60% of any program
                    if (program.distanceLearningId > minDistanceLearning &&
                        program.distanceLearningId > programsSummary.distanceLearning) {
                        programsSummary.distanceLearning = program.distanceLearningId;
                        programsSummary.distanceLearningType = program.distanceLearningType;
                    }
                });
            }
            return programsSummary;
        };

        factory.isAssigned = function (personId, commissionId) {
            for (var i = 0; i < factory.data.commissioners.length; i++) {
                var commissioner = factory.data.commissioners[i];
                if (commissioner.personId === personId && commissioner.commissionId === commissionId && commissioner.assignedReviews && commissioner.assignedReviews.length > 0) {
                    return true;
                }
            }
            return false;
        }

        factory.releaseTCsAndEditors = function (teamMemberTypeId) {
            var path = '/webapi/odata/ReleaseTCAssignment';
            var tcAssignDto = {
                teamMemberTypeId: teamMemberTypeId,
                reviewYear: factory.data.reviewTeams[0].reviewYear,
                commissionId: commissionIdforReviewTeams
            };
                
            return $http.post(path, tcAssignDto).then(function (response) {
                factory.refreshData();
                return response;
            });
        }

        function assignReleased() {
            var reviewTeams = factory.data.reviewTeams;
            var teamMemberTypes = ['teamChair', 'editor1', 'editor2']

            angular.forEach(teamMemberTypes, function (teamMemberType) {
                var isReleased = reviewTeams !== null && reviewTeams.some(function (review) {
                    if (teamMemberType === 'teamChair') {
                        return review[teamMemberType] !== null && review[teamMemberType].releaseDate !== null && review[teamMemberType].releaseDate !== undefined;
                    }else {
                        return review[teamMemberType] !== null && review[teamMemberType].some(function (teamMember) {
                            return (teamMember.releaseDate !== null && teamMember.releaseDate !== undefined);
                        });
                    }             
                });
                
                if (isReleased) {
                    if (teamMemberType === 'teamChair') {
                        factory.data.teamChairsReleased = true;
                    }
                    if (teamMemberType === 'editor1') {
                        factory.data.editor1Released = true;
                    }
                    if (teamMemberType === 'editor2') {
                        factory.data.editor2Released = true;
                    }
                }
               
            });
            
        }

        // PDF Modal Funcs
        var pdfPath = '/Content/files/TC-Assignment-Instructions-03222022.pdf';
        var pageNumbers = {
            FILTERS: 6,
            MATRIX: 10
        };

        factory.openMasterInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'TC Assignment Instructions');
        };

        factory.openFilterInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Filter Instructions', pageNumbers.FILTERS);
        };

        factory.openMatrixInstructions = function () {
            alertSvc.openPDFModal(pdfPath, 'Filter Instructions', pageNumbers.MATRIX);
        };

        factory.hasTCCOI = function (volunteerId, reviewTeamId, teamMemberTypeId) {
            var data = {
                volunteerId: volunteerId,
                reviewTeamId: reviewTeamId,
                teamMemberTypeId: (teamMemberTypeId ? teamMemberTypeId : null)
            };

            var path = '/webapi/odata/HasTCCOI';

            return $http.post(path, data).then(function (response) {
                return response && response.data ? response.data : "Error";
            });
        }

        return {
            data: factory.data,
            reviewSelectionCounter: factory.reviewSelectionCounter,
            commissionerSelectionCounter: factory.commissionerSelectionCounter,
            getMatrixRecords: factory.getMatrixRecords,
            filters: factory.filters,
            resetFilters: factory.resetFilters,
            preview: factory.preview,
            getReviewTypeHtml: factory.getReviewTypeHtml,
            getTCAssignmentCommissioners: factory.getTCAssignmentCommissioners,
            getTCAssignmentReviewTeams: factory.getTCAssignmentReviewTeams,
            getPevAssignmentReviewPrograms: factory.getPevAssignmentReviewPrograms,
            getCommissionerName: factory.getCommissionerName,
            getDateConflictText: factory.getDateConflictText,
            getDateConflictTypeName: factory.getDateConflictTypeName,
            getConflictDates: factory.getConflictDates,
            getCountryConflictText: factory.getCountryConflictText,
            getConflictCountries: factory.getConflictCountries,
            getProgramsSummary: factory.getProgramsSummary,
            refreshData: factory.refreshData,
            loadAllData: factory.loadAllData,
            isTeamChair: factory.isTeamChair,
            isCoTeamChair: factory.isCoTeamChair,
            isEditor1: factory.isEditor1,
            isEditor2: factory.isEditor2,
            isAssigned: factory.isAssigned,
            refreshReviewTeams: factory.refreshReviewTeams,
            refreshCommissioners: factory.refreshCommissioners,
            releaseTCsAndEditors: factory.releaseTCsAndEditors,
            openMasterInstructions: factory.openMasterInstructions,
            openFilterInstructions: factory.openFilterInstructions,
            openMatrixInstructions: factory.openMatrixInstructions,
            hasTCCOI: factory.hasTCCOI
        };
    };
    module.factory('tcAssignmentSvc', tcAssignmentSvc);

})(angular.module('assignment'));