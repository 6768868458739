(function (module) {

    var helperSvc = function ($q, $uibModal, $window, $filter) {
        var factory = {};
        var august = 7;
        var october = 9;
        var firstDayOfMonth = 1;

        factory.buildFilter = function (property, parameter) {
            return property + ' ' + parameter;
        };

        // returns if key is found within a given string
        factory.strContains = function (str, key) {
            return (str.indexOf(key) !== -1);
        };

        //returns if key is found within a given array
        factory.arrContains = function (array, key) {
            var found = false;

            angular.forEach(array, function (item) {
                if (angular.equals(key, item)) {
                    found = true;
                }
            });

            return found;
        };

        factory.removeDuplicates = function (arr) {
            var i;
            var len = arr.length;
            var result = [];
            var obj = {};
            for (i = 0; i < len; i++) {
                obj[arr[i]] = 0;
            }
            for (i in obj) {
                result.push(i);
            }
            return result;
        };

        //accesses property value from nested object by a given string path
        /*Ex. 
            var someObject = {
                'part1' : {
                    'name': 'Part 1',
                    'size': '20',
                    'qty' : '50'
                }
            };
    
            Object.byString(someObject, 'part1.name'); -> 'Part 1'
    
        */
        factory.getObjectPropertyValueByString = function (o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, '');           // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
            return o;
        }

        // returns if array contains less than 1 element
        factory.isEmpty = function (arr) {
            if (arr) {
                return (arr.length < 1);
            }
            return true;
        }

        // returns undefined or value
        factory.getValue = function (data) {
            var val;

            if (data.value) {
                val = data.value;
            }

            return val;
        };

        // returns undefined or array of objects
        factory.getResults = function (obj) {
            var results;

            if (obj.value && obj.value.length > 0) {
                results = obj.value;
            }
            return results;
        };

        // returns undefined or a single object
        factory.getItem = function (obj) {
            var item;

            if (obj && obj.value && obj.value.length > 0) {
                item = obj.value[0];
            }

            return item;
        };

        //checks to see if the object contains some type of value other than null, undefined or emptystring
        factory.formatEmptyObject = function(object, emptyMessage){
            if (object !== null && object !== undefined && object.toString() !== "") {
                return object;
            }
            return emptyMessage;
        }

        // returns date object required in some places by angular
        factory.formatDate = function (date, useUndefined, keepUTCFormat) {
            if (date == null && !useUndefined) {
                return 'Not Entered';
            }
            else if (date == null && useUndefined) {
                return undefined;
            }

            var dateOut; 
            if (keepUTCFormat) {
                var tempDate = new Date(date);               
                dateOut = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate());
            } else {
               dateOut = new Date(date);
            }

           
            return dateOut;
        };

        factory.formatDateWithCurrent = function (date) {
            if (date === null || date === undefined) {
                return new Date();
            }

            var dateOut;
            dateOut = new Date(date);

            return dateOut;
        }

        factory.getISODate = function (date) {
            // Remove milliseconds for compatibility with WebAPI OData implementation
            source = date || new Date();
            return (source.toISOString().split('.')[0] + "Z");
        };

        factory.formatDateTimeByFilter = function (date) {
            return $filter('amDateFormat')(
                $filter('amTimezone')(
                    date,
                    "America/New_York"),
                "M/D h:mm A");
        }

        factory.formatDateByFilter = function (date, keepUTCFormat) {
            var dateOut;

            if (keepUTCFormat) {
                var tempDate = new Date(date);
                dateOut = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(), tempDate.getUTCDate());
            } else {
                dateOut = new Date(date);
            }

            return $filter('date')(dateOut, "M/d/yyyy");
        }

        factory.deserializeDateFields = function (obj) {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop) && prop.endsWith('Date')) {
                    try {
                        obj[prop] = obj[prop] && new Date(obj[prop]) || obj[prop];
                    } catch (error) {
                        console.log(`${prop}is not a date`);
                    }
                }
            }
        }

        // Returns url string prepended with http:// or https:// as needed; does not validate resulting URL
        factory.formatUrl = function (url, protocol) {
            if (url === undefined || url === null || url.trim().length === 0) {
                return '#';
            };
            var cleanUrl = url.toLowerCase().trim();

            //need to keep case sensitivity after the http
            if (cleanUrl.startsWith('http://')) {
                var endOfUrl = url.substring(7)
                return 'http://' + endOfUrl;
            }
            if(cleanUrl.startsWith('https://')) {
                var endOfUrl = url.substring(8)
                return 'https://' + endOfUrl;
            };
            if (!protocol) {
                protocol = 'http://';
            } else if (!protocol.endsWith('://')) {
                protocol += '://';
            }
            return protocol + url;
        }

        // Return format (xxx) xxx-xxxx when input is 10-digit num,
        // return a tab character in front of a 12-digit+ number to prevent excel from displaying in scientific mode,
        // otherwise returns input
        factory.getFormattedPhone = function (num) {
            if (num) {
                var phone = num.toString();
                if (phone.length == 10) {
                    return formatPhone(phone);
                } else if (phone.charAt(0) == "+") {
                    return '{0} {1}'.format(phone.substr(0, phone.length - 10), formatPhone(phone.substr(phone.length - 10)))
                } else if (phone.length >= 12) {
                    return '\t' + phone;
                }
            }
            return num;

            function formatPhone(phone) {
                var phoneArr = phone.split("");

                phoneArr.splice(0, 0, "(");
                phoneArr.splice(4, 0, ")");
                phoneArr.splice(5, 0, " ");
                phoneArr.splice(9, 0, "-");

                return phoneArr.join("");
            }
        };

        // returns comma seperated list of properties from an array of objects
        factory.getPropertyString = function (array, property, defaultText) {
            var text = '';

            if (array && array.length > 0) {
                text = array.map(function (item) {
                    return item[property];
                }).join(', ');
            }
            if (text === '' && defaultText) {
                text = defaultText;
            }


            return text;
        };

        // return Yes for true, No for false, Not entered for anything else
        factory.getBooleanText = function (bool) {
            var text = 'Not entered';

            if (bool === true) {
                text = 'Yes';
            } else if (bool === false) {
                text = 'No';
            }

            return text;
        };

        //convert string to a boolean
        factory.convertToBool = function (value) {

            var strValue = String(value).toLowerCase();

            strValue = ((!isNaN(strValue) && strValue !== '0') &&
                strValue !== '' &&
                strValue !== 'null' &&
                strValue !== 'undefined') ? '1' : strValue;

            return strValue === 'true' || strValue === '1' ? true : false
        };

        factory.addBodyClass = function (className) {
            var body = angular.element(document.querySelector('body'));
            body.addClass(className);
        };

        factory.removeBodyClass = function (className) {
            var body = angular.element(document.querySelector('body'));
            body.removeClass(className);
        };

        // converts a year into a range starting at the previous year
        // i.e. 2018 returns 2017–18
        factory.getYearSpan = function (year) {
            if (isNaN(year)) {
                return year;
            } else {
                var prevYear = year - 1;
                var yearLastTwoDigits = year % 100;
                var separator = (yearLastTwoDigits < 10) ? "-0" : "-"; // If year is 2008, display last two digits as 08 rather than just 8.

                return prevYear + separator + yearLastTwoDigits;
            }
        };

        // checks if any instance of an array nested as a property of
        // a parent array contains 1 or more elements
        factory.isNestedArrayEmpty = function (arr, nestedArr) {
            var empty = true;

            if (arr && arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i][nestedArr].length > 0) {
                        empty = false;
                    }
                }
            }
            return empty;
        };

        // convert key property in code array from string to int
        factory.keysToInt = function (array) {
            angular.forEach(array, function (code) {
                code.codeKey = parseInt(code.codeKey);
            });
        };

        // replaces dash with en dash surrounded by spaces or returns field
        factory.replaceEnDash = function (field) {
            if (field) {
                if (factory.strContains(field, "-")) {
                    return field.replace("-", " – ");
                } else {
                    return field;
                }
            }
            else {
                return 'Not entered';
            }
        };

        //returns integer that represents the academic year based on the current date
        //if isReadinessCycle = true, cycle changes on March 1st, not October 1st
        factory.getAcademicYear = function (isReadinessCycle) {
            var currentDate = new Date();

            if (!isReadinessCycle) {
                //  before October(9)
                return currentDate.getMonth() < 9 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
            }
            else {
                //  before March(2)
                return currentDate.getMonth() < 2 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
            }

            //var march = 2;
            //var december = 11;
            //var lastDayOfDecember = 31;
            //var startDate = isReadinessCycle ? new Date(currentDate.getFullYear(), march, firstDayOfMonth) : new Date(currentDate.getFullYear(), october, firstDayOfMonth);
            //var endDate = new Date(currentDate.getFullYear(), december, lastDayOfDecember);
            //var reviewYear;

            //if (currentDate >= startDate && currentDate <= endDate) {
            //    reviewYear = currentDate.getFullYear() + (isReadinessCycle ? 0 : 1);
            //}
            //else {
            //    reviewYear = currentDate.getFullYear() - (isReadinessCycle ? 1 : 0);
            //}
            //return reviewYear;
        };

        //returns date that current academic cycle ends
        factory.getAcademicYearEndDate = function () {
            return new Date(factory.getAcademicYear(), october, firstDayOfMonth);
        };

        //returns date that current academic cycle starts
        factory.getAcademicYearStartDate = function () {
            return new Date(factory.getAcademicYear() - 1, october, firstDayOfMonth);
        };

        factory.getCommissionerYear = function () {
            var currentDate = new Date();
            return currentDate.getMonth() < august ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
        }

        factory.getCommissionerNominationYear = function () {
            // Commissioners serve from 8/1 - 7/31
            var monthsOffset = 5;
            var commissionerYearDate = new Date();
            commissionerYearDate.setMonth(commissionerYearDate.getMonth() + monthsOffset);
            var commissionerNominationYear = commissionerYearDate.getFullYear() + 1;

            return commissionerNominationYear;
        }

        factory.getCommissionerNominationsClosedDate = function () {
            var nominationYear = factory.getCommissionerNominationYear();
            // nominations close on April 1st--no foolin'!
            var nominationsClosedDate = new Date(nominationYear, 3, 1);
        }

        factory.getDateDiff = function(date1,date2,interval) {
                var second=1000, minute=second*60, hour=minute*60, day=hour*24, week=day*7;
                date1 = new Date(date1);
                date2 = new Date(date2);
                var timediff = date2 - date1;
                if (isNaN(timediff)) return NaN;
                switch (interval) {
                    case "years": return date2.getFullYear() - date1.getFullYear();
                    case "months": return (
                        ( date2.getFullYear() * 12 + date2.getMonth() )
                        -
                        ( date1.getFullYear() * 12 + date1.getMonth() )
                    );
                    case "weeks"  : return Math.floor(timediff / week);
                    case "days"   : return Math.floor(timediff / day); 
                    case "hours"  : return Math.floor(timediff / hour); 
                    case "minutes": return Math.floor(timediff / minute);
                    case "seconds": return Math.floor(timediff / second);
                    default: return undefined;
                }
            }

        //returns an array of arrays that only has two entries.
        //the first array, resultArray[0], is an array filled with objects that have a desired property and value
        //the second array, resultArray[1], is filled with objects that do not have the desired property and value
        //example: [ [{matchingItem0, matchingItem1}], [notMatchingItem0, notMatchingItem1, notMatchingItem3] ]
        factory.getArrayByFieldValue = function (array, property, valuesArray) {
            var resultArray = [];
            var matchingArray = [];
            var notMatchingArray = [];
            if (!Array.isArray(valuesArray)) { valuesArray = [valuesArray] };

            for (var i = 0; i < array.length; i++) {
                var object = array[i];

                //if object doesnt have property this might be broken
                if (valuesArray.includes(object[property])) {
                    matchingArray.push(object);
                }
                else {
                    notMatchingArray.push(object);
                }
            }

            resultArray.push(matchingArray);
            resultArray.push(notMatchingArray);

            return resultArray;
        };

        //returns an array of arrays that only has two entries.
        //the first array, resultArray[0], is an array filled with objects for which the specified test function returns true
        //the second array, resultArray[1], is filled with objects for which the specified test function returns false
        //example: [ [matchingItem0, matchingItem1], [notMatchingItem0, notMatchingItem1, notMatchingItem3] ]
        factory.getArrayByCriteriaFunction= function (array, criteriaFunc) {
            var resultArray = [];
            var matchingArray = [];
            var notMatchingArray = [];

            for (var i = 0; i < array.length; i++) {
                var object = array[i];

                //if object doesnt have property this might be broken
                if (criteriaFunc(object)) {
                    matchingArray.push(object);
                }
                else {
                    notMatchingArray.push(object);
                }
            }

            resultArray.push(matchingArray);
            resultArray.push(notMatchingArray);

            return resultArray;
        };

        factory.scrollToModalTop = function () {
            document.getElementById('modal-body').scrollTop = 0;
        };

        factory.scrollToModalBottom = function () {
            var modalBody = document.getElementById('modal-body');
            modalBody.scrollTop = modalBody.scrollHeight;
        }

        factory.validateForm = function (validationFunc) {
            factory.scrollToModalTop();
            if (validationFunc && typeof validationFunc === 'function') validationFunc();
        };

        //processes an array of dataObjects and places the final result of a service call within the dataLocation on the dataHolder
        //returns a promise
        //
        //EXAMPLE dataSourceArray
        //var dataSourceArray = [
        //    {
        //        storeData: true,                  //if set to false function will not try to save data anywhere, useful if data storing is already handled in service for example
        //        dataHolder: model
        //        dataLocationName: 'person',
        //        svcCallback: nameSvc.getNameById OR [nameSvc.getNameById, nameSvc.GetNameByIdChildren], 
        //        svcCallbackArguments: [ person.id ],
        //        optionalCallback: function (firstName, lastName) {
        //            model.foundPerson = true;
        //        },
        //        optionalCallbackArguments: [ firstName, lastName ]
        //        helperCallback: helperSvc.getItem,
        //        odataResource: true,
        //        orderByProperty: 'propertyName'   //property to order by so the combined svc callbacks stay correct,
        //                                          //use if svcCallback is array. NOW ONLY INT PROPERTIES WILL WORK, HAVE NOT IMPLEMENTED STRING COMPARISON         
        //    }
        //];
        factory.getData = function (dataSourceArray) {
            var promises = [];
            var currentPromise = null;

            for (var i = 0; i < dataSourceArray.length; i++) {

                if (dataSourceArray[i].svcCallback.constructor === Array) {
                    for (var j = 0; j < dataSourceArray[i].svcCallback.length; j++) {
                        setPromises(dataSourceArray[i], dataSourceArray[i].svcCallback[j]);
                    }
                } else {
                    setPromises(dataSourceArray[i], dataSourceArray[i].svcCallback);
                }
            }

            return $q.all(promises).then(function (data) {

                var currentDataIndex = 0;

                for (var i = 0; i < dataSourceArray.length; i++) {
                    //go to next iteration of loop if storing data is explicitly turned off
                    if (dataSourceArray[i].storeData === false) {
                        currentDataIndex++;
                        continue;
                    }

                    var currentData = null;

                    //check if array lengths match for split service call promises
                    if (data.length !== dataSourceArray.length && dataSourceArray[i].svcCallback.constructor === Array) {

                        //get the next n amount of datas that matches svcCallback # and add the data to the temp array
                        var tempArray = [];

                        for (var j = 0; j < dataSourceArray[i].svcCallback.length; j++) {
                            var temp = formatAndApplyHelperCallback(data, dataSourceArray[i], currentDataIndex+j);

                            tempArray.push(temp);
                        }

                        currentData = getSplitDataAndMerge(tempArray, dataSourceArray[i].orderByProperty);
                        storeDataAndApplyOptionalCallback(dataSourceArray[i], currentData);

                        //add to the current data array because it consumes additional promises but subtract the promise being currently consumed
                        currentDataIndex += dataSourceArray[i].svcCallback.length - 1;

                    } else {
                        currentData = formatAndApplyHelperCallback(data, dataSourceArray[i], currentDataIndex);
                        storeDataAndApplyOptionalCallback(dataSourceArray[i], currentData);
                    }

                    currentDataIndex++;
                }
            });

            function setPromises(dataSourceElem, svcCallbackElem) {
                currentPromise = svcCallbackElem.apply(null, dataSourceElem.svcCallbackArguments);
                promises.push(currentPromise);
            }

            function formatAndApplyHelperCallback(data, dataSourceElem, index) {
                var updatedData = dataSourceElem.odataResource ? data[index] : [data[index]];
                if (dataSourceElem.helperCallback) {
                    updatedData = dataSourceElem.helperCallback.apply(null, updatedData);
                }
                return updatedData;
            }

            function storeDataAndApplyOptionalCallback(dataSourceElem, currentData) {
                var dataLocationName = dataSourceElem.dataLocationName.split('.');

                if (dataLocationName.length === 1) {
                    dataSourceElem.dataHolder[dataLocationName[0]] = currentData;
                } else if (dataLocationName.length === 2) {
                    dataSourceElem.dataHolder[dataLocationName[0]][dataLocationName[1]] = currentData;
                } else {
                    console.error('dataLocationName should be a property nested no more than two levels deep, i.e. model.prop1.prop2');
                }

                if (dataSourceElem.optionalCallback) {
                    dataSourceElem.optionalCallback.apply(null, dataSourceElem.optionalCallbackArguments);
                }
            }
        };

        function getSplitDataAndMerge(dataSet, property) {
            var mergedSets = [];
            var mergedObject = null;

            //ORDER BY THE passed in property, uses integer for now only
            if (property !== null || property !== undefined) {
                for (var i = 0; i < dataSet.length; i++) {
                    dataSet[i].sort(function (a, b) {
                        if (parseInt(a[property]) > parseInt(b[property])) {
                            return 1;
                        }
                        if (parseInt(a[property]) < parseInt(b[property])) {
                            return -1;
                        }
                        return 0;
                    });
                }
            }

            for (var j = 0; j < dataSet[0].length; j++) {
                mergedObject = dataSet[0][j];

                for (var i = 1; i < dataSet.length; i++) {
                    mergedObject = angular.extend(mergedObject, dataSet[i][j]);
                }
                mergedSets.push(mergedObject);
            }
            
            return mergedSets;
        }

        factory.updateArrayByArrayProperty = function (array, property, object) {
            var tempArray = [];

            tempArray = $.grep(array, function (elem) {
                 return elem[property] !== object[property];       
            });

            tempArray.push(object);

            return tempArray;
        }

        factory.isArray = function (obj) {
            return Object.prototype.toString.call(obj) === '[object Array]';
        };


        factory.uniqueArrayByKey = function (arr, keyname) {
            var uniqueArr = [],
                keys = [];

            angular.forEach(arr, function (item) {
                var key = item[keyname];
                if (keys.indexOf(key) === -1) {
                    keys.push(key);
                    uniqueArr.push(item);
                }
            });
            return uniqueArr;
        };

        factory.getFirstArrayItem = function (array) {
            if (Array.isArray(array)) {
                if (array.length > 0) {
                    return array[0];
                }
            }
        }

        //adds a space after specified character with comma being default
        factory.addSpace = function (str, char) {
            str = str || '';
            char = char || ',';
            var pattern = new RegExp(char, 'g');
            var replacement = char + ' ';
            return str.replace(pattern, replacement);
        };

        //filter an array of objects by using a specified property based on an array of values to filter
        factory.getFilteredArray = function (sourceArray, sourceProperty, filterValues, useIncludeMode) {
            var filteredArray = [];
            var valuesArray = [];
            if (!Array.isArray(filterValues)) {
                valuesArray.push(filterValues);
            } else {
                valuesArray = filterValues;
            }

            for (var i = 0; i < sourceArray.length; i++) {
                var item = sourceArray[i];
                var isFound = factory.arrContains(valuesArray, item[sourceProperty]);

                if ((useIncludeMode === true && isFound) || (!useIncludeMode && !isFound)) {
                        filteredArray.push(item);
                }
            }

            return filteredArray;
        };

        factory.getFirstMatch = function(sourceArray, sourceProperty, value, returnIndex){
            var valuesArray = [];
            if (!Array.isArray(value)) {
                valuesArray.push(value);
            } else {
                valuesArray = value;
            }


            for (var i = 0; i < sourceArray.length; i++) {
                var item = sourceArray[i];
                var isFound = factory.arrContains(valuesArray, item[sourceProperty]);

                if (returnIndex && isFound)
                    return i;

                if (isFound)
                    return item;
            }

            return null;
        }

        //pass in a single value or an array of values
        factory.arrayContainsByPropertyValue = function(array, property, values){
            var valuesArray = [];
            if (!Array.isArray(values)) {
                valuesArray.push(values);
            } else {
                valuesArray = values;
            }

            var i = array.length;
            while (i--) {
                var item = array[i];

                for (var j = 0; j < valuesArray.length; j++) {
                    if (item[property] === valuesArray[j]) {
                        return true;
                    }
                }
            }
            return false;
        }

        //pass in a single value or an array of values
        //true if array contains only matching property values
        factory.arrayOnlyContainsByPropertyValue = function (array, property, values) {
            if (!Array.isArray(array)) return false;

            var valuesArray = [];
            var hasMatch = false;

            if (!Array.isArray(values)) {
                valuesArray.push(values);
            } else {
                valuesArray = values;
            }

            var i = array.length;
            while (i--) {
                var item = array[i];

                for (var j = 0; j < valuesArray.length; j++) {
                    if (item[property] === valuesArray[j]) {
                        hasMatch = true;
                    }
                }
                if (!hasMatch) return false;
            }
            return true;
        }

        factory.getCurrentReviewCycleStartDate = function (odataString) {
            var date = new Date();
            var startDate = new Date(Date.UTC(date.getFullYear(), 7, 1));
            if (odataString)
                return startDate.getFullYear().toString() + '-08-01T00:00:00.00Z'
            return startDate;
        }

        factory.getCurrentReviewCycleEndDate = function (odataString) {
            var date = new Date();           
            var endDate = new Date(Date.UTC(date.getFullYear() + 1, 6, 31));
            if (odataString)
                return endDate.getFullYear().toString() + '-07-31T00:00:00.00Z'
            return endDate;
        }

        //gets value from a given query string varibale
        //Ex. www.url.com/home?key=1 -> getQueryStringVariable('key') returns '1'
        factory.getQueryStringVariable = function (variable) {
            var query = window.location.href.split("?")[1];
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) { return pair[1]; }
            }
            return (false);
        }
        
        
        factory.isIE = function () {
            return Number.isInteger(detectIE());
        }

        //returns true if browser is IE 11 or less (non Edge browsers)
        factory.isIE11 = function () {
            var version = detectIE();
            return Number.isInteger(version) && version <= 11;
        }

        //returns true if browser is IE 10 or less
        factory.isIE10 = function () {
            var version = detectIE();
            return Number.isInteger(version) && version <= 10;
        };

        factory.isFirefox = function () {
            return navigator.userAgent.toLowerCase().includes('firefox');
        }

        //deletes '@odata.context' property from object and returns it
        //used for passing the correct format of objects to the API; 
        //if the data from the service is being stored from a get using a parameter in the url,
        // IE. /myCall(id=1) the oData service will add on the '@odata.context' property which needs to be removed before saving
        factory.removeODataContext = function (obj) {
            delete obj['@odata.context'];
            return obj;
        };

        //appends script tag to the head of the document
        factory.loadScript = function (url, type, charset) {
            if (type === undefined) type = 'text/javascript';
            if (url) {
                var script = document.querySelector("script[src*='" + url + "']");
                if (!script) {
                    var heads = document.getElementsByTagName("head");
                    if (heads && heads.length) {
                        var head = heads[0];
                        if (head) {
                            script = document.createElement('script');
                            script.setAttribute('src', url);
                            script.setAttribute('type', type);
                            if (charset) script.setAttribute('charset', charset);
                            head.appendChild(script);
                        }
                    }
                }
                return script;
            }
        };

        //detects what version of IE the browser is...
        //returns version number of IE, false if not IE
        function detectIE() {
            var ua = $window.navigator.userAgent;
            // IE 10
            // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

            // IE 11
            // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

            // Edge 12 (Spartan)
            // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

            // Edge 13
            // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf('rv:');
                return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }

            var edge = ua.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
            }

            // other browser
            return false;
        }

        factory.isSafari = function () {
            return Number.isInteger(detectSafari());
        }

        //returns true if browser is IE 11 or less (non Edge browsers)
        factory.isSafari10 = function () {
            var version = detectSafari();
            return Number.isInteger(version) && version <= 10;
        }

        //detects version of Safari browser (false if not Safari); modelled after detectIE
        function detectSafari() {
            var ua = $window.navigator.userAgent;

            if (!ua.contains('Safari') || ua.contains('Chrome'))
                return false;

            var versionOffset= ua.indexOf('Version/');
            if (versionOffset > 0) {
                // return version number
                return parseInt(ua.substring(versionOffset + 8, ua.indexOf('.', versionOffset)), 10);
            }

            // other browser
            return false;
        }

        factory.getAmsUrl = function () {
            return window.location.origin;
        }

        factory.deleteProperties = function (object, properties) {
            // Validate parameters
            if (!object || typeof object !== 'object' || !Array.isArray(properties)) return;
            // Remove specified properties from object
            properties.forEach(function (property) {
                if (object.hasOwnProperty(property)) {
                    delete object[property];
                }
            });
            // Remove properties on member objects
            for (var property in object) {
                if (object.hasOwnProperty(property)) {
                    factory.deleteProperties(object[property], properties);
                }
            }
            return object;
        }

        factory.seed = (new Date()).getTime();
        factory.getNextKey = function () {
            // This should genereate a sequential numeric value on each call that is unique to the client.
            // Note: could be improved in the future to use a hash of local information along with a sequence to generate a UUID.
            return factory.seed++;
        }

        factory.getNewKey = function () {
            return Math.random().toString(36).substr(2, 9);
        }

        factory.getPropertyValues = function (obj) {
            return Object.getOwnPropertyNames(obj).map(
                function (prop) {
                    return obj[prop]
                });
        }

        factory.formatFilename = function (title, filetype) {
            var filename = title;
            filename = filename.replace('<', '_');
            filename = filename.replace('>', '_');
            filename = filename.replace(':', '_');
            filename = filename.replace('"', '_');
            filename = filename.replace('\\', '_');
            filename = filename.replace('/', '_');
            filename = filename.replace('|', '_');
            filename = filename.replace('?', '_');
            filename = filename.replace('*', '_');
            if (filetype && !filename.endsWith(filetype)) {
                if (filetype.charAt(0) !== '.') {
                    filetype = '.' + filetype;
                }
                filename = filename + filetype;
            }

            return filename;
        }
        factory.anyDifferentValueInArray = function (arr) {
            var hasDifference = false;

            for (let i = 0; i < arr.length; i++) {
                for (let k = i + 1; k < arr.length; k++) {
                    if (arr[i] != arr[k]) {
                        hasDifference = true;
                    }
                }
            }
            return hasDifference;
        }

        return {
            buildFilter: factory.buildFilter,
            strContains: factory.strContains,
            arrContains: factory.arrContains,
            removeDuplicates: factory.removeDuplicates,
            isEmpty: factory.isEmpty,
            getValue: factory.getValue,
            getResults: factory.getResults,
            getItem: factory.getItem,
            formatDate: factory.formatDate,
            formatDateWithCurrent: factory.formatDateWithCurrent,
            getISODate: factory.getISODate,
            formatDateTimeByFilter: factory.formatDateTimeByFilter,
            formatDateByFilter: factory.formatDateByFilter,
            deserializeDateFields: factory.deserializeDateFields,
            getFormattedPhone: factory.getFormattedPhone,
            getPropertyString: factory.getPropertyString,
            getBooleanText: factory.getBooleanText,
            getYearSpan: factory.getYearSpan,
            isNestedArrayEmpty: factory.isNestedArrayEmpty,
            keysToInt: factory.keysToInt,
            replaceEnDash: factory.replaceEnDash,
            getAcademicYear: factory.getAcademicYear,
            getAcademicYearEndDate: factory.getAcademicYearEndDate,
            getAcademicYearStartDate: factory.getAcademicYearStartDate,
            getCommissionerYear: factory.getCommissionerYear,
            getCommissionerNominationYear: factory.getCommissionerNominationYear,
            getCommissionerNominationsClosedDate: factory.getCommissionerNominationsClosedDate,
            getArrayByFieldValue: factory.getArrayByFieldValue,
            getArrayByFieldValueArray: factory.getArrayByFieldValueArray,
            getArrayByCriteriaFunction: factory.getArrayByCriteriaFunction,
            scrollToModalTop: factory.scrollToModalTop,
            scrollToModalBottom: factory.scrollToModalBottom,
            validateForm: factory.validateForm,
            getData: factory.getData,
            getDataHolder: factory.getDataHolder,
            updateArrayByArrayProperty: factory.updateArrayByArrayProperty,
            isArray: factory.isArray,
            uniqueArrayByKey: factory.uniqueArrayByKey,
            formatEmptyObject: factory.formatEmptyObject,
            convertToBool: factory.convertToBool,
            addBodyClass: factory.addBodyClass,
            removeBodyClass: factory.removeBodyClass,
            formatUrl: factory.formatUrl,
            getFirstArrayItem: factory.getFirstArrayItem,
            addSpace: factory.addSpace,
            getFilteredArray: factory.getFilteredArray,
            getFirstMatch: factory.getFirstMatch,
            arrayContainsByPropertyValue: factory.arrayContainsByPropertyValue,
            arrayOnlyContainsByPropertyValue: factory.arrayOnlyContainsByPropertyValue,
            getCurrentReviewCycleStartDate: factory.getCurrentReviewCycleStartDate,
            getCurrentReviewCycleEndDate: factory.getCurrentReviewCycleEndDate,
            getQueryStringVariable: factory.getQueryStringVariable,
            isIE: factory.isIE,
            isIE10: factory.isIE10,
            isIE11: factory.isIE11,
            isFirefox: factory.isFirefox,
            isSafari: factory.isSafari,
            isSafari10: factory.isSafari10,
            removeODataContext: factory.removeODataContext,
            loadScript: factory.loadScript,
            getDateDiff: factory.getDateDiff,
            getAmsUrl: factory.getAmsUrl,
            deleteProperties: factory.deleteProperties,
            getObjectPropertyValueByString: factory.getObjectPropertyValueByString,
            getNextKey: factory.getNextKey,
            getNewKey: factory.getNewKey,
            getPropertyValues: factory.getPropertyValues,
            formatFilename: factory.formatFilename,
            anyDifferentValueInArray: factory.anyDifferentValueInArray
        };
    };

    module.service('helperSvc', helperSvc);

})(angular.module('common'));